import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";

//Scss
import "../../styles/components/Launch.scss"

const Launch = ({slice}) => (
   <div className="launch bg-gray_dark">
      <div className="container mx-auto flex flex-wrap py-16 px-5 justify-between">
         {slice.items.map((item, index) => (
            <div data-sal="fade"
                 data-sal-easing="ease" className="lg:w-5/12 mb-10 lg:mb-0 relative" key={`item=${index}`}>
               <svg className="absolute top-5 right-5" width="75%"
                    height="80%"
                    version="1.1">
                  {/*<rect className="rect" width="100%" height="100%" style={{fill: "transparent", strokeWidth: 5, stroke: '#000'}} />*/}
                  <line className="animateLine lineVertical" x1="100%" y1="0%" x2="100%" y2="100%" style={{stroke: "#fff", strokeWidth: 5}}/>
                  <line className="animateLine lineHorizontal2" x1="100%" y1="100%" x2="0%" y2="100%" style={{stroke: "#fff", strokeWidth: 5}}/>
                  <line className="animateLine lineVertical2" x1="0%" y1="100%" x2="0%" y2="0%" style={{stroke: "#fff", strokeWidth: 5}}/>
               </svg>
               <div className="wrapper relative z-10">
                  <div className="text-4xl lg:text-5xl font-serif text-white bg-gray_dark pb-5 mr-20 md:mr-10">
                     <PrismicRichText field={item.title.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>
                  <div className="text-white text-xl pb-5 mr-20 md:mr-10 bg-gray_dark">
                     <PrismicRichText field={item.launch_description.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>
                  <div className="block md:flex justify-between items-start">
                     <div className="bg-gray_dark pb-10">
                        <div className="btn-with-white-after-parent relative inline-block">
                           <a className={Utils.prototype.btnClass('my-btn-white-outline') + ' block bg-gray_dark px-10'} href={Utils.prototype.linkResolver(item.launch_link)}>{item.link_text ?? 'Scopri di più'}</a>
                        </div>
                     </div>
                     <Imgix
                        className="lazyload rounded-full mt-10 ml-auto w-4/12 md:w-auto lg:w-4/12 opacity-0 transition-opacity duration-300 delay-1000"
                        src={item.launch_image.url}
                        width={item.launch_image.dimensions.width}
                        height={item.launch_image.dimensions.height}
                        htmlAttributes={{
                           alt: item.launch_image.alt ?? ''
                        }}
                        attributeConfig={{
                           src: 'data-src',
                           srcSet: "data-srcset",
                           sizes: "data-sizes",
                        }}/>
                  </div>
               </div>
            </div>
         ))}
      </div>
   </div>
)


export default Launch
