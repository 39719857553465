import Imgix from "react-imgix";
import React, {useState} from "react";
import Carousel
   from "react-multi-carousel";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../../Utilities";
import FsLightbox
   from "fslightbox-react";

const SingleService = ({item, index}) => {

   const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1
   });

   function openLightboxOnSlide(number) {
      //console.log('OPEN LIGHT GALLERY')
      setLightboxController({
         toggler: !lightboxController.toggler,
         slide: number
      });
   }
   // Set sources of gallery
   let sources = []
   const sources_list = ['image', 'image_two', 'image_three', 'image_four', 'image_five', 'image_six', 'image_seven', 'image_eight']

   let item_array = Object.keys(item).map((key) => [item[key]]);
   sources_list.map((element, index) => {
      if(item_array[index+2][0].url) {
         //console.log('Image ==> ', item_array[index+2][0].url)
         if(index % 2 === 0) {
            sources.push(item_array[index+2][0].url)
         } else {
            sources.push(item_array[index+2][0].thumbnails.large.url)
         }
      }
   })

   //console.log(sources);

   return (
      <div
         className="container mx-auto py-16">
         <FsLightbox
            toggler={lightboxController.toggler}
            sources={sources}
            slide={lightboxController.slide}
         />
         <div
            className="lg:w-10/12 mx-auto">
            <div
               className={`w-full flex flex-wrap ${index % 2 !== 0 ? 'justify-end' : ''}`}>
               <div
                  className={`flex flex-wrap w-full overflow-hidden ${index % 2 !== 0 ? 'justify-end' : ''}`}>
                  <Carousel
                     className="mt-5 react-multi-carousel-v2 mx-5"
                     additionalTransfrom={0}
                     arrows={false}
                     autoPlay
                     autoPlaySpeed={3000}
                     centerMode={false}
                     dotListClass=""
                     draggable
                     focusOnSelect={false}
                     infinite={true}
                     itemClass=""
                     keyBoardControl
                     renderButtonGroupOutside={false}
                     renderDotsOutside={false}
                     pauseOnHover={false}
                     responsive={{
                        all: {
                           breakpoint: {
                              max: 9000,
                              min: 0
                           },
                           items: 1
                        },
                     }}
                     showDots={false}
                     sliderClass=""
                     slidesToSlide={1}
                     swipeable>
                     {/*w-4/12 lg:w-3/12*/}
                     {item.image.url ? (
                        <div
                           onClick={() => openLightboxOnSlide(1)}
                           className={`flex cursor-pointer ${index % 2 !== 0 ? 'lg:justify-end' : null}`}>
                           {item.image.url ? (
                              <ImageItem
                                 classes={"lg:w-3/12"}
                                 item={item.image}/>
                           ) : null}
                           {item.image_two.url ? (
                              <ImageItem
                                 classes={"lg:w-6/12 pl-6 pr-10 xl:pl-9 xl:pr-12 hidden lg:block"}
                                 item={item.image_two.thumbnails.large}/>
                           ) : null}
                        </div>
                     ) : null}

                     {item.image_three.url ? (
                        <div
                           onClick={() => openLightboxOnSlide(3)}
                           className={`flex cursor-pointer ${index % 2 !== 0 ? 'lg:justify-end' : null}`}>
                           {item.image_three.url ? (
                              <ImageItem
                                 classes={"lg:w-3/12"}
                                 item={item.image_three}/>
                           ) : null}
                           {item.image_four.url ? (
                              <ImageItem
                                 classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12 hidden lg:block"}
                                 item={item.image_four.thumbnails.large}/>
                           ) : null}
                        </div>) : null}

                     {item.image_five.url ? (
                        <div
                           onClick={() => openLightboxOnSlide(5)}
                           className={`flex cursor-pointer ${index % 2 !== 0 ? 'lg:justify-end' : null}`}>
                           {item.image_five.url ? (
                              <ImageItem
                                 classes={"lg:w-3/12"}
                                 item={item.image_five}/>
                           ) : null}
                           {item.image_six.url ? (
                              <ImageItem
                                 classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12 hidden lg:block"}
                                 item={item.image_six.thumbnails.large}/>
                           ) : null}
                        </div>) : null}

                     {item.image_seven.url ? (
                        <div
                           onClick={() => openLightboxOnSlide(7)}
                           className={`flex cursor-pointer ${index % 2 !== 0 ? 'lg:justify-end' : null}`}>
                           {item.image_seven.url ? (
                              <ImageItem
                                 classes={"lg:w-3/12"}
                                 item={item.image_seven}/>
                           ) : null}
                           {item.image_eight.url ? (
                              <ImageItem
                                 classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12 hidden lg:block"}
                                 item={item.image_eight.thumbnails.large}/>
                           ) : null}
                        </div>) : null}


                     {/*{index % 2 === 0 ? (*/}
                     {/*   // <div className="flex">*/}
                     {/*   //    {item.image ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-3/12"} item={item.image}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image.thumbnails.large ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12"} item={item.image.thumbnails.large}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image_two.src ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-3/12"} item={item.image_two}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image_two.thumbnails.large.src ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12"} item={item.image_two.thumbnails.large}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image_three.src ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12"} item={item.image_three}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image_three.thumbnails.large.src ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12"} item={item.image_three.thumbnails.large}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image_three.src ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12"} item={item.image_three}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   //    {item.image_four.thumbnails.large.src ? (*/}
                     {/*   //       <ImageItem classes={"lg:w-6/12 pl-6 pr-10 xl:pl-8 xl:pr-12"} item={item.image_four.thumbnails.large}/>*/}
                     {/*   //    ) : null}*/}
                     {/*   // </div>*/}
                     {/*) : (*/}
                     {/*   <div className="flex justify-end">*/}
                     {/*      <ImageItem classes={"lg:w-6/12 pr-6 pl-10 xl:pr-8 xl:pl-12"} item={item.image.thumbnails.large}/>*/}
                     {/*      <ImageItem classes={"lg:w-3/12"} item={item.image}/>*/}
                     {/*   </div>*/}
                     {/*)}*/}

                     {/*w-8/12 lg:w-6/12*/}

                  </Carousel>

               </div>
               <div
                  className="px-5 w-full md:w-11/12 lg:w-7/12 xl:w-6/12">
                  <div
                     className="text-4xl lg:text-5xl font-serif mt-10">
                     <PrismicRichText
                        linkResolver={Utils.prototype.linkResolver}
                        field={item.title.richText}/>
                  </div>
                  <div
                     className="text-xl font-light mt-5">
                     <PrismicRichText
                        linkResolver={Utils.prototype.linkResolver}
                        field={item.description.richText}/>
                  </div>
               </div>
            </div>
         </div>
      </div>
)}

export default SingleService;

const ImageItem = ({classes, item}) => (
   <Imgix
      className={`lazyload mt-auto ${classes}`}
      src={item.url}
      width={item.dimensions.width}
      height={item.dimensions.height}
      htmlAttributes={{
         alt: item.alt ?? 'foto prodotto'
      }}
      attributeConfig={{
         src: 'data-src',
         srcSet: 'data-srcset',
         sizes: 'data-sizes',
      }}
   />
)
