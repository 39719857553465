import React from 'react'
import { Background } from 'react-imgix'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import LinkResolver
   from "../LinkResolver";

const ImageCallToAction = ({slice}) => {
   //console.log(slice.primary.image_cta_title.richText.length)
   //console.log(slice.primary.image_cta_image.url)
   if(slice.primary.image_cta_title.richText.length > 0 && slice.primary.image_cta_image.url !== null) {
      return(
         <div className="image_call_to_ation">
            <Background src={slice.primary.aspect_ratio === '16/9' ? slice.primary.image_cta_image.url : slice.primary.image_cta_image.thumbnails.tall.url} className={`bg-gray ${slice.primary.aspect_ratio === '16/9' ? 'aspect-w-16 aspect-h-9 md:aspect-h-6' : 'aspect-w-16 aspect-h-11'}`}>
               <div className="bg-black bg-opacity-40 h-full flex items-center">
                  <div className="container mx-auto px-5 text-center">
                     <div className="text-3xl md:text-4xl lg:text-5xl text-white font-serif">
                        <PrismicRichText field={slice.primary.image_cta_title.richText} linkResolver={Utils.prototype.linkResolver} />
                     </div>
                     <div className="flex justify-center mt-10">
                        <div className="btn-with-white-after-parent relative flex relative">
                           <LinkResolver doc={slice.primary.image_cta_link} classes={Utils.prototype.btnClass('my-btn-white-outline') + ' relative px-20'} label={slice.primary.image_cta_link_text} />
                           {/*<Link className={Utils.prototype.btnClass('my-btn-white-outline') + ' relative px-10'} to={slice.primary.image_cta_link.url}>{slice.primary.image_cta_link_text}</Link>*/}
                        </div>
                     </div>
                  </div>
               </div>
            </Background>
         </div>
      )
   } else if (slice.primary.image_cta_title.richText.length === 0 && slice.primary.image_cta_image.url !== null) {
      return(
         <div className="image_call_to_ation">
            <Background src={slice.primary.aspect_ratio === '16/9' ? slice.primary.image_cta_image.url : slice.primary.image_cta_image.thumbnails.tall.url} className={`bg-gray ${slice.primary.aspect_ratio === '16/9' ? 'aspect-w-16 aspect-h-9 md:aspect-h-6' : 'aspect-w-16 aspect-h-11'}`}>
            </Background>
         </div>
      )
   } else if (slice.primary.image_cta_title.richText.length > 0 && slice.primary.image_cta_image.url === null) {
      return(
         <div className="image_call_to_ation">
               <div className="bg-gray_dark h-full flex items-center py-20">
                  <div className="container mx-auto px-5 text-center">
                     <div className="text-3xl md:text-4xl lg:text-5xl text-white font-serif">
                        <PrismicRichText field={slice.primary.image_cta_title.richText} linkResolver={Utils.prototype.linkResolver} />
                     </div>
                     <div className="flex justify-center mt-10">
                        <div className="btn-with-white-after-parent relative flex">
                           <LinkResolver doc={slice.primary.image_cta_link} classes={Utils.prototype.btnClass('my-btn-white-outline') + ' bg-gray_dark relative px-20'} label={slice.primary.image_cta_link_text} />
                        </div>
                     </div>
                  </div>
               </div>
         </div>
      )
   }
}

export default ImageCallToAction
