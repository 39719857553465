import React from 'react'
import {graphql} from 'gatsby'
import HeroNews from '../components/HeroNews'
import {PrismicRichText} from "@prismicio/react";
import Pagination
   from "../components/Pagination";
import Layout from "../components/Layout"
import {Utils} from "../Utilities"

//css
import "../styles/templates/news.scss"
import {
   withPrismicPreview
} from "gatsby-plugin-prismic-previews";

const News = ({pageContext, data}) => {
   if (!data) return null
   if (!pageContext) return null
   const document = data.prismicNews

   const prismicNavigation = data.prismicNavigation
   return (
      <Layout
         document={document}
         prismicNavigation={prismicNavigation}
      >
         <HeroNews data={document.data} />
         <div className="news-body py-20">
            <div className="container mx-auto px-5">
               <div className="w-full lg:w-10/12 mx-auto text-lg">
                  <PrismicRichText field={document.data.body.richText} linkResolver={Utils.prototype.linkResolver} />
                  <Pagination
                     nodeAttributes={pageContext.node}
                     prevArticle={pageContext.previous}
                     nextArticle={pageContext.next}
                  />
               </div>
            </div>
         </div>
      </Layout>
   )
}

export const query = graphql`
    query NewsQuery($uid: String, $lang: String) {
        prismicNews(uid: { eq: $uid }, lang: {eq: $lang}) {
            _previewable
           id
           uid
           lang
           type
           url
           tags
           alternate_languages {
             id
             type
             lang
             uid
           }
           data {
               meta_title
               meta_description
               title {
                   richText
               }
               body {
                   richText
               }
               date
               image {
                   alt
                   url
                   dimensions {
                       height
                       width
                   }
               }
           }
        }
        prismicNavigation(lang: { eq: $lang }) {
            ...HeaderQuery
            ...FooterQuery
        }
    }
`

export default withPrismicPreview(News)
