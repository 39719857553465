import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import LinkResolver
   from "../LinkResolver";
//scss

const Map = ({slice}) => (
   <div className="text-banner py-20">
      <div className="container mx-auto">
         <div className={`w-full lg:w-10/12 mx-auto`}>
            <div dangerouslySetInnerHTML={{__html: slice.primary.map_iframe}} />
            <div className="flex flex-wrap mt-5">
               {/*{ START images column}*/}
               <div className="w-full lg:w-1/2 flex flex-wrap mx-0 lg:-mx-2.5 order-last lg:order-first">
                  {slice.items[0] ? (
                     slice.items.map((item, index) => (
                        <div key={index} className="sm:w-1/2 px-5 lg:px-2.5">
                           <Imgix
                              className="lazyload"
                              src={item.image.thumbnails.thumb.url}
                              width={item.image.thumbnails.thumb.dimensions.width}
                              height={item.image.thumbnails.thumb.dimensions.height}
                              htmlAttributes={{
                                 alt: item.image.thumbnails.thumb.alt ?? ''
                              }}
                              attributeConfig={{
                                 src: 'data-src',
                                 srcSet: "data-srcset",
                                 sizes: "data-sizes",
                              }}/>
                           <p className="font-serif text-lg italic mt-2">{item.image_description}</p>
                        </div>
                     )
                  )): null}
               </div>
               {/*{ END images column}*/}
               {/*{ START Button and address column}*/}
               <div className="w-full lg:w-1/2 flex flex-wrap order-first lg:order-last mb-10 lg:mb-0">
                  <div className="w-full md:w-1/2 px-3">
                     <div className="text-xl text-center lg:text-left">
                        <PrismicRichText field={slice.primary.map_address.richText} />
                     </div>
                  </div>
                  <div className="w-full md:w-1/2 text-center">
                     <div className="btn-with-gray-after-parent relative inline-block">
                        <LinkResolver doc={slice.primary.map_cta_url} classes={Utils.prototype.btnClass('my-btn-gray_dark-outline') + ' block text-center px-10'} label={slice.primary.map_cta_text} />
                     </div>
                  </div>
               </div>
               {/*{ END Button and address column}*/}
            </div>
         </div>
      </div>

   </div>

)

export default Map
