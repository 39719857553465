import React from 'react'
import { Link, graphql } from 'gatsby'
import {PrismicRichText} from "@prismicio/react";
import { Utils } from '../Utilities'
import LinkResolver from './LinkResolver'
import Imgix from "react-imgix";
import '../utils/font-awesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//Scss
import '../styles/components/Footer.scss'


const isBrowser = typeof window !== `undefined`

const Footer = ({ isHomepage, navigation }) => {
   const homepageClass = isHomepage ? 'homepage-header' : ''
   const mainNav = navigation ? navigation.data.nav_item : null

   if (!navigation) return null
   return (
      <footer id="site-footer" className={`site-footer ${homepageClass}`}>
         <div className="w-full bg-gray_dark py-16">
            <div className="container mx-auto flex flex-wrap px-5">
               <div className="w-full md:w-6/12 mt-5 flex flex-wrap justify-between text-white font-light">
                  <div className="w-full lg:w-1/2">
                     <Link className="py-5" to="/">
                        <Imgix className="site-footer__logo" src={navigation.data.logo.url} height={navigation.data.logo.dimensions.height} width={navigation.data.logo.dimensions.height}
                               htmlAttributes={{
                                  alt: 'New Line'
                               }}
                        />
                     </Link>
                     <div className="ml-3 mt-5 mb-5 lg:mb-0">
                        <PrismicRichText field={navigation.data.contact_info.richText} linkResolver={Utils.prototype.linkResolver}/>
                     </div>
                  </div>
                  <div className="w-full lg:w-1/2 pl-3">
                     <PrismicRichText field={navigation.data.opening_timens.richText} linkResolver={Utils.prototype.linkResolver}/>
                  </div>
               </div>
               <div className="w-full md:w-3/12 lg:w-3/12 mt-5 pl-3 md:pl-0 hidden lg:block">
                  <nav>

                     <div className="site-footer__toggle flex flex-wrap relative items-center mb-5 ml-auto">
                        <div className="flex flex-wrap site-footer__toggle__icon">
                           <span className={`site-footer__toggle__line mb-3 bg-white`} />
                           <span className={`site-footer__toggle__line bg-white`} />
                        </div>
                     </div>
                     {/*<PrismicRichText field={navigation.data.telephone} />*/}
                     <ul className="mt-3">
                        {mainNav.map((navItem, index) => {
                           if(!navItem.sub_menu_link) {
                              return (
                                 <li className="mb-3 font-serif" key={`link-${index}`}>
                                    <LinkResolver classes="text-2xl text-white" doc={navItem.link} label={navItem.title} />
                                 </li>
                              )
                           }
                        })}
                     </ul>
                  </nav>
               </div>
               <div className="w-full md:w-3/12 lg:w-3/12 mt-5 pl-3 md:pl-0">
                  <ul className="flex flex-wrap">
                     {navigation.data.social_links.map((item, index) => {
                        return (
                           <li className="text-white text-xl mr-3" key={`social-link-${index}`}>
                              <a className="p-3 hover:opacity-70" href={item.link.url} target='_blank'>
                                 <FontAwesomeIcon icon={["fab", `${item.class_icon}`]} />
                              </a>
                           </li>
                        )
                     })}
                  </ul>
               </div>
            </div>
         </div>
         <div className="w-full bg-white pt-5">
            <div className="container mx-auto flex flex-wrap px-5">
               <p>All right reserved | new-line.ch©{new Date().getFullYear()}
                  {navigation.data.sub_footer.map((subFooterItem, index) => {
                     return (
                        <LinkResolver key={index} doc={subFooterItem.footer_link_url} label={' - ' + subFooterItem.footer_link_title}/>
                     )
                  })}</p>
            </div>
         </div>
      </footer>
   )
}

export const query = graphql`
    fragment FooterQuery on PrismicNavigation {
        _previewable
        url
        type
        tags
        lang
         data {
             logo {
                 url
                 dimensions {
                     height
                     width
                 }
             }
             logo_url {
                 slug
             }
             email
             telephone
             contact_info {
                 richText
             }
             opening_timens {
                 richText
             }
             sub_footer {
                 footer_link_url {
                     type
                     uid
                     lang
                     link_type
                     url
                 }
                 footer_link_title
             }
             nav_item {
                 title
                 link {
                     slug
                     type
                     uid
                     lang
                     link_type
                     url
                 }
             }
             social_links {
                 link {
                     url
                     type
                     slug
                     link_type
                     target
                 }
                 class_icon
             }
         }
     }
`

export default Footer
