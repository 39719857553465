import React from 'react'
import {graphql} from 'gatsby'
import SliceZone
   from '../components/SliceZone'
import Layout
    from "../components/Layout";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const HomePage = ({pageContext, data}) => {
    if (!data) return null
    if (!pageContext) return null

   const document = data.prismicHomePage

   const prismicNavigation = data.prismicNavigation

   return (
      <Layout
         document={document}
         prismicNavigation={prismicNavigation}
      >
         <SliceZone sliceZone={document.data.body} nodeAttributes={pageContext}/>
     </Layout>
   )
}

export const query = graphql`
    query PageQuery($uid: String, $lang: String) {
        prismicHomePage(uid: {eq: $uid}, lang: {eq: $lang}) {
            _previewable
            url
            uid
            type
            tags
            lang
            alternate_languages {
                id
                type
                lang
                uid
            }
                data {
                    meta_title
                    meta_description
                    main_page_color
                    body {
                        __typename
                        ... on PrismicHomePageDataBodyHero {
                            id
                            slice_type
                            primary {
                                main_color
                                title {
                                    html
                                    richText
                                    text
                                }
                                sub_title {
                                    html
                                    richText
                                    text
                                }
                                image {
                                    alt
                                    url
                                    dimensions {
                                        width
                                        height
                                    }
                                }
                                card_text {
                                    richText
                                }
                                card_cta_link {
                                    url
                                    target
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                card_cta_text
                            }
                        }
                        ... on PrismicHomePageDataBodyIntro {
                            id
                            slice_type
                            primary {
                                intro_body {
                                    richText
                                }
                                intro_image_1 {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                            }
                            items {
                                intro_logo_image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyIntroV3 {
                            id
                            slice_type
                            primary {
                                description {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyLaunch {
                            id
                            slice_type
                            items {
                                launch_description {
                                    richText
                                }
                                launch_image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                launch_link {
                                    url
                                    target
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                link_text
                                title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyImageCallToAction {
                            id
                            slice_type
                            primary {
                                aspect_ratio
                                image_cta_link_text
                                image_cta_link {
                                    url
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                image_cta_image {
                                    url
                                    alt
                                    thumbnails {
                                        tall {
                                            alt
                                            dimensions {
                                                height
                                                width
                                            }
                                            url
                                        }
                                    }
                                }
                                image_cta_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyCardsGridLinks {
                            id
                            slice_type
                            primary {
                                cards_grid_links_text {
                                    richText
                                }
                                cards_grid_links_title {
                                    richText
                                }
                            }
                            items {
                                cards_grid_links_background_image {
                                    alt
                                    url
                                }
                                link {
                                    url
                                    target
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                round_image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyTitleDescription {
                            id
                            slice_type
                            primary {
                                title_description_text {
                                    richText
                                }
                                title_description_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyImageSlider {
                            id
                            slice_type
                            primary {
                                image_slider_title {
                                    richText
                                }
                            }
                            items {
                                image_slider_image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyNewsletterSubscription {
                            id
                            slice_type
                            primary {
                                newsletter_subscription_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyImagesGrid {
                            id
                            items {
                                images_grid_image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                video {
                                    thumbnail_width
                                    thumbnail_url
                                    thumbnail_height
                                    embed_url
                                    html
                                    provider_url
                                }
                            }
                            slice_type
                            primary {
                                first_is_big
                                background_color
                                images_grid_button_text
                                images_grid_button_link {
                                    url
                                    target
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                images_grid_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyCallToActionRich {
                            id
                            slice_type
                            items {
                                image {
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                    url
                                }
                            }
                            primary {
                                call_to_action_rich_button_link {
                                    url
                                    target
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                call_to_action_rich_button_text
                                call_to_action_rich_image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                call_to_action_rich_text {
                                    richText
                                }
                                call_to_action_rich_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyNews {
                            id
                            slice_type
                            primary {
                                news_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyNewsAcademy {
                            id
                            slice_type
                            primary {
                                news_title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyIntroV2 {
                            id
                            slice_type
                            primary {
                                cta_link {
                                    isBroken
                                    link_type
                                    uid
                                    tags
                                    lang
                                    url
                                    type
                                }
                                cta_text
                                description {
                                    richText
                                }
                                image_left {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                image_right {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyListOfServices {
                            id
                            slice_type
                            items {
                                title {
                                    richText
                                }
                                description {
                                    richText
                                }
                                image {
                                    alt
                                    copyright
                                    url
                                    thumbnails {
                                        large {
                                            alt
                                            dimensions {
                                                height
                                                width
                                            }
                                            url
                                        }
                                    }
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                image_two {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            dimensions {
                                                height
                                                width
                                            }
                                            url
                                        }
                                    }
                                }
                                image_three {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                                image_four {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                                image_five {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                                image_six {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                                image_seven {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                                image_eight {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyCustomerLogos {
                            id
                            slice_type
                            primary {
                                number_of_lines
                                description {
                                    richText
                                }
                                headline {
                                    richText
                                }
                            }
                            items {
                                link {
                                    url
                                    type
                                    link_type
                                    uid
                                    tags
                                    lang
                                }
                                logo {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyMaterialList {
                            id
                            slice_type
                            items {
                                item_title {
                                    richText
                                }
                                item_text {
                                    richText
                                }
                                image_one {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                image_two {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                image_three {
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                    url
                                }
                            }
                            primary {
                                headline {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyDesignStyledList {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyIcons {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                    text
                                }
                                column_number
                                title_type
                            }
                            items {
                                icon {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                icon_alignment
                                icon_color
                                icon_plus
                                icon_equal
                                round
                                title_size
                                icon_title {
                                    richText
                                    text
                                }
                                icon_text {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyList {
                            id
                            slice_type
                            items {
                                list_text
                            }
                            primary {
                                title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyTextBanner {
                            id
                            slice_type
                            primary {
                                width
                                banner_type
                                bg_color
                                title {
                                    richText
                                }
                                text_color
                                text {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyMap {
                            id
                            slice_type
                            primary {
                                map_address {
                                    richText
                                }
                                map_cta_text
                                map_iframe
                                map_cta_url {
                                    url
                                    target
                                    uid
                                    tags
                                    lang
                                    type
                                    link_type
                                }
                            }
                            items {
                                image_description
                                image {
                                    thumbnails {
                                        thumb {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyTimeLine {
                            id
                            primary {
                                title {
                                    richText
                                }
                            }
                            items {
                                item_title {
                                    richText
                                }
                                item_description {
                                    richText
                                }
                            }
                            slice_type
                        }
                        ... on PrismicHomePageDataBodyVideo {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                }
                                video {
                                    html
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyDivisions {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                }
                                image {
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                    url
                                }
                            }
                            items {
                                cta_link {
                                    url
                                    uid
                                    tags
                                    lang
                                    target
                                    link_type
                                    type
                                }
                                cta_text
                                item_description {
                                    richText
                                }
                                item_image {
                                    copyright
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    alt
                                }
                                item_logo {
                                    url
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                title {
                                    richText
                                }
                                video {
                                    html
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyTeamSection {
                            id
                            slice_type
                            items {
                                category
                                name_surname {
                                    richText
                                }
                                image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                    thumbnails {
                                        large {
                                            alt
                                            url
                                            dimensions {
                                                height
                                                width
                                            }
                                        }
                                    }
                                }
                                role
                                description {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyRealEstateObjectsList {
                          id
                          slice_type
                        }
                        ... on PrismicHomePageDataBodyTwoColumnsText {
                            id
                            slice_type
                            primary {
                                number_of_columns
                            }
                            items {
                                font_type
                                text {
                                    richText
                                }
                                image {
                                    alt
                                    url
                                    dimensions {
                                        height
                                        width
                                    }
                                }
                                image_description
                            }
                        }
                        ... on PrismicHomePageDataBodyContactForm {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyAllNews {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                }
                            }
                        }
                        ... on PrismicHomePageDataBodyAcademyrender {
                            id
                            slice_type
                        }
                        ... on PrismicHomePageDataBodyContestparticipation {
                            id
                            slice_type
                            primary {
                                title {
                                    richText
                                }
                                image {
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                    url
                                }
                            }
                            slice_label
                        }
                    }
                }
        }
        prismicNavigation(lang: { eq: $lang }) {
            ...HeaderQuery
            ...FooterQuery
        }
    }
`

export default withPrismicPreview(HomePage)
