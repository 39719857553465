import {Background} from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import React from "react";
import {Utils} from "../Utilities";

const HeroNews = ({data}) => (
   <div className="hero">
      <Background src={data.image.url} className="h-screen bg-gray" width={data.image.dimensions.width} height={data.image.dimensions.height}>
         <div className="bg-black bg-opacity-30 h-full flex items-center">
            <div className="container mx-auto px-5">

               <p className="text-white">{data.date}</p>
               <div className="text-3xl md:text-4xl lg:text-6xl text-white">
                  <PrismicRichText field={data.title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            </div>
         </div>
      </Background>
   </div>
)

export default HeroNews
