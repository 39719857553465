import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//scss
import "../../styles/components/CallToActionRich.scss"
import LinkResolver
   from "../LinkResolver";

const generic_number =  () => {
      return Math.floor((Math.random() * 3) + 1);
}

const number = generic_number() - 1;

const CallToActionRich = ({slice}) => (
   <div className="call_to_action_rich pt-20 pb-40 overflow-hidden">
      <div className="container mx-auto px-5">
         <div className="w-full lg:w-12/12 mb-10 md:mb-0 mx-auto relative">
            <svg className="absolute top-11 w-full md:w-1/2"
                 height="100%"
                 version="1.1">
               {/*<rect className="rect" width="100%" height="100%" style={{fill: "transparent", strokeWidth: 5, stroke: '#000'}} />*/}
               <line className="animateLine lineHorizontal" x1="0%" y1="0%" x2="100%" y2="0%" style={{stroke: "#000", strokeWidth: 5}}/>
               <line className="animateLine lineVertical" x1="100%" y1="0%" x2="100%" y2="100%" style={{stroke: "#000", strokeWidth: 5}}/>
               <line className="animateLine lineHorizontal2" x1="100%" y1="100%" x2="0%" y2="100%" style={{stroke: "#000", strokeWidth: 5}}/>
               <line className="animateLine lineVertical2" x1="0%" y1="100%" x2="0%" y2="0%" style={{stroke: "#000", strokeWidth: 5}}/>
            </svg>
            <div className="wrapper z-30">
               <div className="md:w-6/12 lg:w-5/12 xl:w-4/12 text-4xl lg:text-5xl font-serif pb-5 inline-block bg-white z-10 relative">
                  <PrismicRichText field={slice.primary.call_to_action_rich_title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
               <div className='flex flex-wrap items-start -mx-5'>
                  <div className="order-1 md:order-0 md:w-6/12 lg:w-5/12 xl:w-4/12 px-5 pb-10 bg-white  z-10 relative">
                     <div className="text-xl font-light leading-7">
                        <PrismicRichText field={slice.primary.call_to_action_rich_text.richText} linkResolver={Utils.prototype.linkResolver} />
                     </div>
                     <div className="flex justify-center mt-10">
                        <div className="btn-with-gray-after-parent flex relative">
                           <LinkResolver doc={slice.primary.call_to_action_rich_button_link} classes={Utils.prototype.btnClass('my-btn-gray-outline') + ' px-20'} label={slice.primary.call_to_action_rich_button_text} />
                        </div>
                     </div>
                  </div>
                  <div className='order-0 pb-10 md:pb-0 md:order-1 md:w-6/12 lg:w-7/12 xl:w-8/12 px-5 bg-white z-10 relative'>
                     {slice.items.length > 1 && slice.items.includes(number) !== -1 ? (
                        <Imgix
                           data-sal="fade" data-sal-easing="ease"

                           className="lazyload"
                           src={slice.items[number].image.url}
                           width={slice.items[number].image.dimensions.width}
                           height={slice.items[number].image.dimensions.height}
                           htmlAttributes={{
                              alt: slice.items[number].image.alt ?? ''
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: "data-srcset",
                              sizes: "data-sizes",
                           }}
                        />
                     ) : (<Imgix
                        className="lazyload"
                        src={slice.primary.call_to_action_rich_image.url}
                        width={slice.primary.call_to_action_rich_image.dimensions.width}
                        height={slice.primary.call_to_action_rich_image.dimensions.height}
                        htmlAttributes={{
                           alt: slice.primary.call_to_action_rich_image.alt ?? ''
                        }}
                        attributeConfig={{
                           src: 'data-src',
                           srcSet: "data-srcset",
                           sizes: "data-sizes",
                        }}
                     />) }
                  </div>
               </div>
            </div>

         </div>
      </div>

   </div>

)

export default CallToActionRich
