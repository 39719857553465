import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import Imgix from "react-imgix";
import Carousel
   from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import '../../styles/components/PartnersSection.scss';

const PartnersSection = ({slice}) => (
   <div className="partners_section overflow-x-hidden">
      <div className="bg-gray_dark">
         <div className="container mx-auto py-16">
            <div className="w-full lg:w-10/12 mx-auto px-5 text-white">
               <div className="text-4xl lg:text-5xl font-serif">
                  <PrismicRichText field={slice.primary.headline.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
               <div className="text-xl md:w-11/12 lg:w-6/12 font-light mt-5">
                  <PrismicRichText field={slice.primary.description.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            </div>
         </div>
      </div>
      <div className="container mx-auto pt-5 pb-16">
         <div className="w-full lg:w-10/12 px-5 mx-auto text-white">
               <div className="flex -mx-2 overflow-x-hidden">
                  <Carousel
                     additionalTransfrom={0}
                     arrows={true}
                     autoPlay
                     autoPlaySpeed={3000}
                     centerMode={false}
                     className="react-multi-carousel-v2"
                     dotListClass=""
                     draggable
                     focusOnSelect={false}
                     infinite={true}
                     itemClass=""
                     keyBoardControl
                     minimumTouchDrag={80}
                     renderButtonGroupOutside={false}
                     renderDotsOutside={false}
                     responsive={{
                        desktop: {
                           breakpoint: {
                              max: 3000,
                              min: 1480
                           },
                           items: 6
                        },
                        laptop: {
                           breakpoint: {
                              max: 1480,
                              min: 1024
                           },
                           items: 4
                        },
                        tablet: {
                           breakpoint: {
                              max: 1024,
                              min: 0
                           },
                           items: 2
                        },
                        // mobile: {
                        //    breakpoint: {
                        //       max: 464,
                        //       min: 0
                        //    },
                        //    items: 1
                        // }
                     }}
                     showDots={false}
                     sliderClass=""
                     slidesToSlide={1}
                     swipeable
                  >
                     {slice.primary.number_of_lines === "2" ?
                        (slice.items.map((item, index) =>
                              {
                                 return index % 2 === 0 ? (
                                    <div className="before:bg-gray-light" key={`brand-${index}`}>
                                       <Logo item={slice.items[index]} />
                                       {index + 1 < slice.items.length ? (
                                          <Logo item={slice.items[index + 1]} />
                                       ) : (<div className="p-2 with-pseudo h-1/2 w-full absolute">&nbsp;</div>)}
                                    </div>
                                 ) : null
                              }
                           ))
                     : (
                        (slice.items.map((item, index) =>
                              {
                                 return (
                                    <div className="before:bg-gray-light" key={`brand-${index}`}>
                                       <Logo item={item} />
                                    </div>
                                 )
                              }
                           ))
                        )}
                  </Carousel>
               </div>
            </div>
      </div>
   </div>
)

const Logo = ({item}) => (
   <div className="p-2 with-pseudo relative">
      {item.link.link_type === 'Web' ? (
         <a href={Utils.prototype.linkResolver(item.link)} target="_blank" rel="noreferrer">
            <Imgix
               className="lazyload"
               src={item.logo.url}
               width={item.logo.dimensions.width}
               height={item.logo.dimensions.height}
               htmlAttributes={{
                  alt: item.logo.alt ?? ''
               }}
               attributeConfig={{
                  src: 'data-src',
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
               }}/>
         </a>
      ): (
         <Imgix
            className="lazyload"
            src={item.logo.url}
            width={item.logo.dimensions.width}
            height={item.logo.dimensions.height}
            htmlAttributes={{
               alt: item.logo.alt ?? ''
            }}
            attributeConfig={{
               src: 'data-src',
               srcSet: "data-srcset",
               sizes: "data-sizes",
            }}/>
      ) }

   </div>
)
export default PartnersSection
