import React, {useState} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import JSONDataEn from "../translations/en.json"
// import JSONDataIt from "../translations/it.json"
import {Utils} from "../Utilities";
import {Translations} from "../Translations";

//@TODO: ADD RECAPTCHA

const InputClassName = "w-full mb-4 font-light italic placeholder-gray border-2 border-gray_dark focus:border-gray focus:ring-0"
const ContactForm = ({additionalFields = null, nodeAttributes, subject= Translations.prototype.getTranslations(nodeAttributes.lang).contact_form.contact_subject}) => {
   const [status, setStatus] = React.useState(``)
   const [serverResponse, setServerResponse] = React.useState(``)
   const [captcha, setCaptcha] = React.useState(``)
   let recaptchaRef = React.useRef()
   let translations = Translations.prototype.getTranslations(nodeAttributes.lang)
   //Set Default forms value
   const [value, setValue] = React.useState({
      text: translations.contact_form.message,
      lang: nodeAttributes.lang,
      subject: subject,
      ...additionalFields[0]
   })

   function captchaValue(response) {
      setCaptcha(response);
      console.log('CAPTCHA ===> ', response);
      let fakeEvent = {
         target: {
            id: 'captcha',
            value: response
         }
      }
      handleChange(fakeEvent)
   }

   // Listen to form changes and save them.
   function handleChange(e) {
      value[e.target.id] = e.target.value
      setServerResponse(``)
      //console.log(value)

      setValue({ ...value })
      console.log("value => ", value)
   }

   // When the form is submitted, send the form values
   // to our function for processing.
   async function onSubmit(e) {
      e.preventDefault()
      //console.log(e);
      //console.log(value);
      //console.log('captcha ==> ', captcha)
      if(captcha) {
         setStatus({
            message: translations.contact_form.request_loading,
            status: 'waiting'
         }) //Set status as waiting when await for response
         const response = await window
            .fetch(`/api/contact`, {
               method: `POST`,
               headers: {
                  "content-type": "application/json",
               },
               body: JSON.stringify(value)
            })
            .then(res => res.json())
         setServerResponse(response)
         setStatus(response.status)
         //Push GA event
         if(response.status === 200 && window.dataLayer) {
            window.dataLayer.push({
               'event': 'submit',
               'event_category': 'form_submission',
               'event_label': 'contact_request',
               'value': 1
            })

            window.dataLayer.push({
               'event': 'contact_request',
            })
         }
      } else {
         setCaptcha(null)
      }

   }

   return (
      <form onSubmit={onSubmit} className="pb-44">
         <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-5">
               <input className={`${InputClassName} `} onChange={handleChange} value={value[`first_name`] || ``} name="first_name" id="first_name" type="text" placeholder={translations.contact_form.name} required />
               <input className={`${InputClassName} `} onChange={handleChange} value={value[`last_name`] || ``} name="last_name" id="last_name" type="text" placeholder={translations.contact_form.surname} required />
               <input className={`${InputClassName} `} onChange={handleChange} value={value[`email`] || ``} name="email" id="email" type="email" placeholder={translations.contact_form.email} required />
               <input className={`${InputClassName} `} onChange={handleChange} value={value[`phone_number`] || ``} name="phone_number" id="phone_number" type="text" placeholder={translations.contact_form.tel} />
               {additionalFields ? (
                  <div>
                     {additionalFields.map((elem, index) => (
                        <div key={index}>
                           <input type="hidden" name={elem.name} value={value[elem.name] || elem.value}/>
                        </div>
                     ))}
                  </div>
               ) : null}
            </div>
            <div className="w-full lg:w-6/12 px-5">

               <textarea className={`${InputClassName} min-h-full py-5`} onChange={handleChange} value={value[`text`] || ``} name="text" id="text" />
               <div className="my-5">
                  <ReCAPTCHA
                     sitekey={process.env.RECAPTCHA_KEY}
                     onChange={captchaValue}
                  />
                  {captcha == null && <p className="inline-block text-sm mb-5 py-2 px-3 bg-red text-white">{ translations.contact_form.robot_field }</p>}

               </div>
                     {serverResponse.status !== 200 &&
                     <div className="inline-block pb-2">
                        <div className="btn-with-gray-after-parent relative">
                           <button type="submit" className="relative bg-white text-xl hover:bg-gray_dark text-gray_dark font-semibold hover:text-white py-2 px-4 border-2 border-gray_dark hover:border-transparent px-20 focus:ring-0">
                              { translations.contact_form.send }
                           </button>
                        </div>
                     </div>
                     }
               {serverResponse.status === 'waiting' && <p className="inline-block text-sm mb-5 py-2 px-3 bg-green text-white">{serverResponse.message}</p>}
               {serverResponse.status === 200 && <p className="inline-block text-sm mb-5 py-2 px-3 bg-green text-white">{serverResponse.message}</p>}
               {(serverResponse.status === 500 || serverResponse.status === 400) && <p className="inline-block text-sm mb-5 py-2 px-3 bg-red text-white">{serverResponse.message}</p>}
            </div>
         </div>
      </form>
   )
}


export default ContactForm
