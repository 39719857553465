import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import {
   graphql,
   Link,
   StaticQuery
} from 'gatsby'

const DesignStyledList = ({slice, nodeAttributes}) => (
   <StaticQuery
      query={graphql`
      {
        allPrismicDesignStyles(sort: {fields: first_publication_date}) {
          edges {
            node {
              id
              uid
              lang
              type
              url
              type
              tags
              alternate_languages {
                id
                type
                lang
                uid
              }
              data {
                preview_title {
                    richText
                }
                 thumbnail {
                     alt
                     url
                     dimensions {
                       height
                       width
                     }
                }
                body {
                  ... on PrismicDesignStylesDataBodyHero {
                    id
                    primary {
                      title {
                        richText
                      }
                      image {
                        alt
                        dimensions {
                          height
                          width
                        }
                        url
                      }
                    }
                  }
                  ... on PrismicDesignStylesDataBodyMasonry {
                    id
                    primary {
                      heading {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
         
      }
    `}
      render={data => (

         <div className="styles py-20">
            <div className="container mx-auto px-5">
               <div className="w-full lg:w-10/12 mx-auto">
                  <div className="text-4xl lg:text-5xl font-serif inline-block mb-10">
                     <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>
               </div>
            </div>
            <div className="bg-gray_dark py-10">
               <div className="container mx-auto px-5">
                  <div className="w-full lg:w-10/12 mx-auto flex flex-wrap items-end">


                     {data.allPrismicDesignStyles.edges.map((element, index) => {
                        return element.node.lang ===  nodeAttributes.lang ? (
                           <div className="px-5 mb-10 w-full md:w-1/2 xl:w-1/3" key={`item=${index}`}>
                              <div>
                                 <Link to={Utils.prototype.linkResolver(element.node)}>
                                    <div className="text-3xl text-white mb-5">
                                       <PrismicRichText field={element.node.data.preview_title.richText} />
                                    </div>
                                    <Imgix
                                       className="lazyload"
                                       src={element.node.data.thumbnail.url}
                                       width={element.node.data.thumbnail.dimensions.width}
                                       height={element.node.data.thumbnail.dimensions.height}
                                       htmlAttributes={{
                                          alt: element.node.data.thumbnail.alt ?? ''
                                       }}
                                       attributeConfig={{
                                          src: 'data-src',
                                          srcSet: "data-srcset",
                                          sizes: "data-sizes",
                                       }}/>
                                    />
                                 </Link>
                              </div>
                              {/*<PrismicRichText field={element.node.data.body.primary.title.richText} />*/}
                              {/*<Link to={Utils.prototype.linkResolver(element.node)}>*/}
                              {/*   <Imgix src={element.node.data.image.url} alt={element.node.data.image.alt} width={element.node.data.image.dimensions.width} height={element.node.data.image.dimensions.height} />*/}
                              {/*</Link>*/}
                              {/*<p className="font-serif text-sm mt-5 italic">{element.node.data.date}</p>*/}
                              {/*<div className="text-xl lg:text-2xl font-bold">*/}
                              {/*   <LinkResolver doc={element.node} label={element.node.data.title.text} />*/}
                              {/*</div>*/}
                           </div>
                        ): null})}
                  </div>
               </div>
            </div>
         </div>
      )}
   />
)

export default DesignStyledList
