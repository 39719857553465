import "./src/styles/global.css"
import "./src/styles/style.scss"
import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { PrismicProvider } from '@prismicio/react'

import { repositoryConfigs } from './src/utils/prismicPreviews'
import {Utils} from "./src/Utilities";

export const wrapRootElement = ({ element }) => (
   <PrismicProvider
      linkResolver={Utils.prototype.linkResolver}
      internalLinkComponent={({ href, ...props }) => (
         <Link to={href} {...props} />
      )}
   >
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
         {element}
      </PrismicPreviewProvider>
   </PrismicProvider>
)

