import React, { useState, useEffect, useRef } from "react";

const CloseMessage = ({ children, document, prismicNavigation }) => {
  return (
    <div>
      <h2 className='text-3xl lg:text-4xl font-serif leading-snug lg:leading-tight text-green'>
        Chiusura
        <br />
        Lunedì 4 marzo.
      </h2>

      <p className='text-xl mt-2'>
        Avvisiamo la gentile clientela che il nostro showroom resterà
        eccezionalmente chiuso <strong>lunedi 4 marzo</strong> a causa di alcuni
        lavori nel nostro stabile che richiedono l’interruzione di corrente
        elettrica.
        <br />
        <br />
        Ci scusiamo per il disagio, saremo regolarmente aperti da{" "}
        <strong>martedi 5 marzo.</strong>
        <br />
        Grazie per l’attenzione
      </p>
    </div>
  );
};

export default CloseMessage;
