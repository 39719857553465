import React from 'react'
import { Background } from 'react-imgix'
import {PrismicRichText} from "@prismicio/react";
import ContactCard from '../ContactCard'
import {Utils} from "../../Utilities";

const Hero = ({slice, showContactCard}) => (
   <div className="hero">
      <Background src={slice.primary.image.url} className="h-screen bg-gray" width={slice.primary.image.dimensions.width} height={slice.primary.image.dimensions.height}>
         <div className="bg-black bg-opacity-30 h-full flex items-center">
            <div className="container mx-auto px-5">
               <div className="text-4xl md:text-5xl lg:text-6xl leading-snug lg:leading-tight text-white">
                  <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
               {slice.primary.sub_title ? (
                  <div className="text-white text-2xl mt-3">
                     <PrismicRichText field={slice.primary.sub_title.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>
               ) : null}

            </div>
         </div>
      </Background>
      <div className="container mx-auto px-5 md:h-0">
         <div className="transform -translate-y-10 md:-translate-y-full w-full md:w-1/2 lg:w-1/3 xl:w-1/4 md:ml-auto">
            {slice.primary.card_text ? (slice.primary.card_text.richText.length > 0 ?
               (slice.primary.main_color ? (
                  <ContactCard cardText={slice.primary.card_text.richText} cardCtaText={slice.primary.card_cta_text} cardLink={slice.primary.card_cta_link} />
               ):(
                  <ContactCard cardText={slice.primary.card_text.richText} cardCtaText={slice.primary.card_cta_text} cardLink={slice.primary.card_cta_link} />
               ))
               : null) : null

            }
         </div>
      </div>
   </div>
)
export default Hero
