import React, {useState} from 'react';
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import ReCAPTCHA
   from "react-google-recaptcha";
import NewsletterForm
   from "../NewsletterForm";

const NewsletterSubscription = ({slice, tags}) => {

   {{ console.log('NEWLSETEER TITLE => ', slice.primary.newsletter_subscription_title.richText) }}
   {{ console.log('TAGS => ', tags) }}
   return (
      <NewsletterForm tags={tags} heading={slice.primary.newsletter_subscription_title.richText} />
   )
}

export default NewsletterSubscription
