import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import {Utils} from "../Utilities";

const Seo = ({ title, nodeAttributes, description }) => {

   const defaultTitle = 'New Line'
   const defaultDescription = 'New Line Lugano arredamento, mobili, arredobagno Lugano, porte , parquet, progettazione wellness, bagnoturco, sauna, spa, bagnoarredo Ticino.'
   const defaultLang = 'it'

   const seo = {
      title: title || defaultTitle,
      lang: nodeAttributes.lang || defaultLang,
      description: description || defaultDescription,
   }

   return (
      <Helmet title={seo.title}>
         {/* html attributes */}
         <html lang={seo.lang} />
         <meta name="description" content={seo.description} />
         <meta name="image" content={seo.image} />

         {seo.url && <meta property="og:url" content={seo.url} />}


         {seo.title && <meta property="og:title" content={seo.title} />}

         {seo.description && (
            <meta property="og:description" content={seo.description} />
         )}

         <link rel="canonical" href={Utils.prototype.linkResolver(nodeAttributes)} />
         {/*<link rel="alternate" href={Utils.prototype.linkResolver(nodeAttributes.alternate_languages[0])} hrefLang={nodeAttributes.alternate_languages[0].lang} key={nodeAttributes.alternate_languages[0].lang}/>*/}
         {nodeAttributes.alternate_languages.map((locale, index) => {
            // <meta property="og:type" content={seo.description} />
            //console.log('CIAO');
            <link rel="alternate" href={Utils.prototype.linkResolver(locale)} hrefLang={locale.lang} key={`locale=${index}`}/>
         })}
         {/*<script type="text/javascript">*/}
         {/*   {var ciao = 'ciao'}*/}
         {/*</script>*/}
         {/*<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charSet="UTF-8" async/>*/}

      </Helmet>
   )
}

export default Seo

Seo.propTypes = {
   title: PropTypes.string,
   description: PropTypes.string,
   image: PropTypes.string,
   article: PropTypes.bool,
}

Seo.defaultProps = {
   title: null,
   description: null,
   image: null,
   article: false,
}
