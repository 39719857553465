import React, { useState } from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import LinkResolver from "../LinkResolver";
import FsLightbox from 'fslightbox-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImageGrid = ({slice}) => {
   // if toggler is updated when lightbox is closed it will open it
   // if toggler is updated when lightbox is opened it will close it
   // Set index of gallery
   const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1
   });

   function openLightboxOnSlide(number) {
      setLightboxController({
         toggler: !lightboxController.toggler,
         slide: number
      });
   }
   // Set sources of gallery
   const sources = slice.items.map((item) =>{
      return item.video !== null && Object.keys(item.video).length > 0 ? Utils.prototype.iframeObj(item.video) : item.images_grid_image.url
   })

   return (
      <div
         className={`overflow-x-hidden image-grid ${slice.primary.background_color === 'white' ? 'bg-white' : 'bg-gray_dark'}`}>
         {/*START Lightbox*/}
         <FsLightbox
            toggler={lightboxController.toggler}
            sources={sources}
            slide={lightboxController.slide}
         />
         {/*END Lightbox*/}
         <div className="container mx-auto px-5 py-16">
            <div
               className="w-full lg:w-10/12 mx-auto">
               {slice.primary.images_grid_title.richText[0] ? (
                  <div
                     className="text-white text-4xl lg:text-5xl font-serif pb-5">
                     <PrismicRichText
                        field={slice.primary.images_grid_title.richText}/>
                  </div>
               ) : null}

               <div
                  className="flex flex-wrap mt-10 justify-center">
                  {slice.primary.first_is_big !== false ? (
                     <div
                        onClick={() => openLightboxOnSlide(1)}
                        onKeyPress={() => openLightboxOnSlide(1)}
                        tabIndex="0"
                        role="button"
                        className={`w-12/12 sm:w-5/12 px-3 cursor-pointer`}>
                        <div className="overflow-hidden relative">
                           <span className="hidden">&nbsp;</span>
                           {slice.items[0].video !== null && slice.items[0].video.length > 0 ? (
                           <FontAwesomeIcon className={`text-white absolute z-30 inset-1/2 text-6xl transform -translate-x-1/2 -translate-y-1/2`} icon={["fal", 'play']} />
                           ) : null}
                           <Imgix
                              className="lazyload transform transition-opacity duration-300 hover:opacity-70"
                              src={slice.items[0].images_grid_image.url}
                              width={slice.items[0].images_grid_image.dimensions.width}
                              height={slice.items[0].images_grid_image.dimensions.height}
                              htmlAttributes={{
                                 alt: slice.items[0].images_grid_image.alt ?? ''
                              }}
                              attributeConfig={{
                                 src: 'data-src',
                                 srcSet: "data-srcset",
                                 sizes: "data-sizes",
                              }}/>
                        </div>
                     </div>
                  ) : null}

                  <div
                     className="w-12/12 sm:w-7/12 flex flex-wrap content-between">
                     {slice.primary.first_is_big === false ? (
                        <div
                           onClick={() => openLightboxOnSlide(1)}
                           onKeyPress={() => openLightboxOnSlide(1)}
                           tabIndex="0"
                           role="button"
                           className={`w-1/2 mt-6 sm:mt-0 sm:w-1/3 px-3 cursor-pointer ${slice.primary.first_is_big === false ? 'mb-5' : null}`}>
                           <div className="overflow-hidden relative">
                              {slice.items[0].video !== null && slice.items[0].video.length > 0 ? (
                                 <FontAwesomeIcon className={`text-white absolute z-30 inset-1/2 text-6xl transform -translate-x-1/2 -translate-y-1/2`} icon={["fal", 'play']} />
                              ) : null}
                              <Imgix
                                 className="lazyload transform transition-opacity duration-300 hover:opacity-70"
                                 src={slice.items[0].images_grid_image.url}
                                 alt={slice.items[0].images_grid_image.alt}
                                 width={slice.items[0].images_grid_image.dimensions.width}
                                 height={slice.items[0].images_grid_image.dimensions.height}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                           </div>
                        </div>
                     ) : null}
                     {slice.items.slice(1, 7).map((item, index) => (
                        <div
                           onClick={() => openLightboxOnSlide(index + 2)}
                           onKeyPress={() => openLightboxOnSlide(index + 2)}
                           role="button"
                           tabIndex="0"
                           key={index}
                           className={`w-1/2 mt-6 sm:mt-0 sm:w-1/3 px-3 cursor-pointer ${item.video ? 'with-video' : ''} ${slice.primary.first_is_big === false ? 'mb-5' : null}`}>
                           <div className="overflow-hidden relative">
                              {item.video !== null && item.video.length > 0 ? (
                                 <FontAwesomeIcon className={`text-white absolute z-30 inset-1/2 text-6xl transform -translate-x-1/2 -translate-y-1/2`} icon={["fal", 'play']} />
                              ) : null}
                              <Imgix
                                 className="transform transition-opacity duration-300 hover:opacity-70"
                                 src={item.images_grid_image.url}
                                 alt={item.images_grid_image.alt}
                                 width={item.images_grid_image.dimensions.width}
                                 height={item.images_grid_image.dimensions.height}
                                 htmlAttributes={{
                                    alt: item.images_grid_image.alt ?? ''
                                 }}
                              />
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
               {/*<a className="" href="#">{slice.primary.images_grid_button_text}</a>*/}
               { slice.primary.images_grid_button_text ? (
                  <div
                     className="text-center mt-10">
                     <div
                        className="btn-with-white-after-parent relative inline-block">
                        <LinkResolver
                           classes={Utils.prototype.btnClass('my-btn-white-outline') + ' block bg-gray_dark px-10'}
                           doc={slice.primary.images_grid_button_link}
                           label={slice.primary.images_grid_button_text}/>
                     </div>
                  </div>
               ): null}

            </div>
         </div>
      </div>
   )
}

export default ImageGrid
