import React, {useState} from 'react';
import Cookies from 'universal-cookie';
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../Utilities";
import ReCAPTCHA
   from "react-google-recaptcha";

const NewsletterSubscription = ({heading, tags, classes}) => {
   const [status, setStatus] = useState(null);
   const [message, setMessage] = useState(null);
   const [serverResponse, setServerResponse] = React.useState(``)
   const [captcha, setCaptcha] = React.useState(``)
   let recaptchaRef = React.useRef();
   //Set Default forms value
   const [value, setValue] = React.useState({
      tags: tags || ''
   })
   const cookies = new Cookies();

   function captchaValue(response) {
      setCaptcha(response);
      let fakeEvent = {
         target: {
            id: 'captcha',
            value: response
         }
      }
      handleChange(fakeEvent)
   }

   const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus('waiting')
      setMessage('Stiamo elaborando la tua richiesta...')
      const response = await window
         .fetch(`/api/newsletter`, {
            method: `POST`,
            headers: {
               "content-type": "application/json",
            },
            body: JSON.stringify(value)
         })
         .then(res => res.json())

      setServerResponse(response)
      setStatus(response.status)

      //Set cookie to prevent newsletter modal
      if(response.status === 200) {
         cookies.set('newsletter-subscribed', 'true', {
            path: '/',
         });
      }
      //Push GA event
      if(response.status === 200 && window.dataLayer) {
         window.dataLayer.push({
            'event': 'submit',
            'event_category': 'form_submission',
            'event_label': 'newsletter_registration',
            'value': 1
         })

         window.dataLayer.push({
            'event': 'newsletter_registration',
         })
      }
   };

   // Listen to form changes and save them.
   function handleChange(e) {
      value[e.target.id] = e.target.value
      setServerResponse(``)
      //console.log(value)

      setValue({ ...value })
   }

   return (
      <div className={`container mx-auto px-5 py-40 ${classes}`}>
         <div className="w-full lg:w-10/12 mx-auto">
            <div className="text-4xl lg:text-5xl font-serif pb-5 mb-5">
               {heading ? (

                  <PrismicRichText field={heading} linkResolver={Utils.prototype.linkResolver}/>
               ): null}
            </div>

            {status === 'waiting' && <p className="inline-block text-sm mb-5 py-2 px-3 bg-green text-white">{message}</p>}
            {status === 200 && <p className="inline-block text-sm mb-5 py-2 px-3 bg-green text-white">{serverResponse.message}</p>}
            {(status === 500 || status === 400) && <p className="inline-block text-sm mb-5 py-2 px-3 bg-red text-white">{serverResponse.message}</p>}

            <form onSubmit={handleSubmit} name="subscribeForm" method="POST" id="subscribe-form" className="subscribe-form">
               <div className="flex flex-wrap -mx-5">
                  <div className="w-full md:w-1/2 lg:w-auto px-5 mb-5">
                     <label className="hidden" htmlFor="firstName">
                        <span className="screen-reader-text">Nome</span>
                     </label>
                     <input onChange={handleChange} value={value[`first_name`] || ``} className="w-full font-serif placeholder-gray border-4 border-gray_dark focus:border-gray focus:ring-0" type="text" name="first_name" id="first_name" placeholder="Nome"/>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-auto px-5 mb-5">
                     <label className="hidden" htmlFor="lastName">
                        <span className="screen-reader-text">Cognome</span>
                     </label>
                     <input onChange={handleChange} value={value[`last_name`] || ``} className="w-full font-serif placeholder-gray border-4 border-gray_dark focus:border-gray focus:ring-0" type="text" name="last_name" id="last_name" placeholder="Cognome"/>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-auto px-5 mb-5">
                     <label className="hidden" htmlFor="email">
                        <span className="screen-reader-text">Indirizzo email</span>
                     </label>
                     <input onChange={handleChange} value={value[`email`] || ``} className="w-full font-serif placeholder-gray border-4 border-gray_dark focus:border-gray focus:ring-0" type="email" required name="email" id="email" placeholder="Indirizzo email"/>
                  </div>
                  <div className="px-5">
                     <div className="btn-with-gray-after-parent relative">
                        {serverResponse.status !== 200 ?
                           (
                              <button type="submit" className="relative bg-white text-xl hover:bg-gray_dark text-gray_dark font-semibold hover:text-white py-2 px-4 border-4 border-gray_dark hover:border-transparent px-20 focus:ring-0">
                                 iscriviti
                              </button>
                           ) : null
                        }
                     </div>
                  </div>
               </div>
               <div className="w-full lg:w-8/12 px-5 mb-5 -mx-5">
                  <ReCAPTCHA
                     sitekey={process.env.RECAPTCHA_KEY}
                     onChange={captchaValue}
                  />
                  {captcha == null && <p className="inline-block text-sm mb-5 py-2 px-3 bg-red text-white">Il campo "non sono un robot" è obbligatorio</p>}

               </div>
            </form>
         </div>
      </div>
   );
}

export default NewsletterSubscription
