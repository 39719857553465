import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import Carousel from 'react-multi-carousel';
import CustomDot from '../CustomDot'
// import TinySlider from "tiny-slider-react";
//scss
import 'react-multi-carousel/lib/styles.css';
import "../../styles/components/ImageSlider.scss"
import {Utils} from "../../Utilities";

const ImageSlider = ({slice}) => (
   <div className="image-slider">
      <div className="lg:container md:mx-auto">
         <div className='flex'>
            <div className="text-4xl lg:text-5xl font-serif px-10 py-5 text-white bg-gray_dark ml-auto">
               <PrismicRichText field={slice.primary.image_slider_title.richText} linkResolver={Utils.prototype.linkResolver} />
            </div>
         </div>
         <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            customDot={<CustomDot />}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
               all: {
                  breakpoint: {
                     max: 3000,
                     min: 0
                  },
                  items: 1
               }
            }}
            showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
         >
            {slice.items.map((item, index) => (
               <Imgix
                  key={index}
                  className="lazyload tns-lazy-img w-full"
                  src={item.image_slider_image.url}
                  width={item.image_slider_image.dimensions.width}
                  height={item.image_slider_image.dimensions.height}
                  htmlAttributes={{
                     alt: item.image_slider_image.alt ?? ''
                  }}
                  attributeConfig={{
                     src: 'data-src',
                     srcSet: "data-srcset",
                     sizes: "data-sizes",
                  }}/>
               // <Picture key={index}>
               //    <Source
               //       src={item.image_slider_image.url}
               //       width={item.image_slider_image.dimensions.width}
               //       height={item.image_slider_image.dimensions.height}
               //       htmlAttributes={{ media: "(max-width: 768px)" }}
               //    />
               //    <Imgix
               //       className="lazyload tns-lazy-img w-full"
               //       src={item.image_slider_image.url}
               //       width={item.image_slider_image.dimensions.width}
               //       height={item.image_slider_image.dimensions.height}
               //       alt={item.image_slider_image.alt}
               //       attributeConfig={{
               //          src: 'data-src',
               //          srcSet: "data-srcset",
               //          sizes: "data-sizes",
               //       }}/>
               // </Picture>
            ))}
         </Carousel>
      </div>
   </div>
)

export default ImageSlider
