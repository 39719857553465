import React from 'react'

const CustomDot = ({index, active, key, onClick, carouselState}) => {
   return (
      <li
         data-index={index}
         key={index}
         className={`react-multi-carousel-custom-dot ${
            active ? "react-multi-carousel-custom-dot--active text-gray_light" : ""
         }`}
      >
         <button
            className="text-2xl lg:text-5xl font-serif mx-3 px-3 focus:outline-none py-3 lg:py-10"
            aria-label={`Go to slide ${index + 1}`}
            onClick={() => onClick()}>{index + 1}</button>
      </li>
   );
}

export default CustomDot
