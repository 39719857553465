import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//Scss

const Video = ({slice}) => (
   <div className="video-widget">
      <div className="container mx-auto px-5 pt-10">
         <div className="w-full lg:w-8/12 mx-auto">
            <div className="text-3xl font-serif italic leading-normal lg:leading-normal pb-5 text-center">
               <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
            </div>
         </div>
         <div className="w-full lg:w-10/12 mx-auto">
            {slice.primary.video.html ? (
               <div className="aspect-w-16 aspect-h-9 mt-14" dangerouslySetInnerHTML={{ __html: slice.primary.video.html }} />
            ) : null}
         </div>
      </div>
   </div>
)
export default Video
