import React from 'react'
import SingleService from "./ListOfServices/SingleService";

//CSS
import "../../styles/components/ListOfServices.scss"
const responsive = {
   items: 2
};

const ListOfServices = ({slice}) => {

   return (
   <div className="list_of_services">

      {slice.items.map((item, index) => (
         <SingleService item={item} index={index} key={index} />
      ))}

   </div>
)}


export default ListOfServices
