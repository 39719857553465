import React from "react";
import LinkResolver
   from "./LinkResolver";
import {Background} from "react-imgix";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Pagination component to render the next and previous links
const Pagination = ({ nextArticle, prevArticle, nodeAttributes }) => (
   <div className="container mx-auto flex flex-wrap justify-between items-stretch pt-20">
      {prevArticle && prevArticle.lang ===  nodeAttributes.lang ? (
         <div className="w-full md:w-5/12 lg:w-1/3 mb-5">
            <PaginationItem article={prevArticle} direction="prev" arrow="angle-left"/>
         </div>
      ) : null}
      {nextArticle && nextArticle.lang ===  nodeAttributes.lang ? (
         <div className="w-full md:w-5/12 lg:w-1/3 mb-5">
            <PaginationItem article={nextArticle} direction="next" arrow="angle-right"/>
         </div>
      ) : null}


   </div>
)

const PaginationItem = ({article, direction, arrow}) => (
   <Background className="relative transition-opacity hover:opacity-70 h-full" src={article.data.image.url}>
      <LinkResolver classes="absolute inset-1" doc={article} />
      <div className={`bg-black bg-opacity-40 px-5 py-3 flex items-center h-full ${direction === 'next' ? 'justify-end' : ''}`}>
         <FontAwesomeIcon className={`text-white text-4xl ${direction === 'prev' ? 'order-0 mr-3' : 'order-1 ml-3'}`} icon={["fal", arrow]} />
         <h4 className={`text-white ${direction === 'prev' ? 'order-1' : 'order-0'}`}>{article.data.title.text}</h4>
      </div>
   </Background>
)

export default Pagination;
