import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { StaticQuery, graphql, Link } from "gatsby";
import SliceZone from "../SliceZone";
//css

const AcademyRender = ({ slice, nodeAttributes }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allPrismicAcademy(sort: { fields: data___date, order: ASC }) {
            edges {
              node {
                id
                uid
                type
                lang
                url
                tags
                data {
                  title {
                    richText
                    text
                  }
                  body {
                    ... on PrismicAcademyDataBodyTextBanner {
                      id
                      slice_type
                      primary {
                        width
                        banner_type
                        bg_color
                        title {
                          richText
                        }
                        text_color
                        text {
                          richText
                        }
                      }
                    }
                    ... on PrismicAcademyDataBodyImagesGrid {
                      id
                      items {
                        images_grid_image {
                          alt
                          url
                          dimensions {
                            height
                            width
                          }
                        }
                        video {
                          thumbnail_width
                          thumbnail_url
                          thumbnail_height
                          embed_url
                          html
                          provider_url
                        }
                      }
                      slice_type
                      primary {
                        first_is_big
                        background_color
                        images_grid_button_text
                        images_grid_button_link {
                          url
                          target
                          type
                          link_type
                          uid
                          tags
                          lang
                        }
                        images_grid_title {
                          richText
                        }
                      }
                    }
                    ... on PrismicAcademyDataBodyApointment {
                      id
                      slice_type
                      primary {
                        appointment_date {
                          richText
                        }
                        appointment_description {
                          richText
                        }
                        appointment_image {
                          alt
                          dimensions {
                            height
                            width
                          }
                          url
                        }
                      }
                      items {
                        appointment_trainer {
                          richText
                        }
                        appointment_trainer_description {
                          richText
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        //Build filters

        return (
          <div className='academy pt-20'>
            <div className='bg-gray_light py-20'>
              <div className='container mx-auto px-5'>
                <div className='w-full mx-auto'>
                  <div className='flex flex-wrap -mx-5'>
                    {data.allPrismicAcademy.edges.map((element, index) => {
                      return element.node.lang === nodeAttributes.lang ? (
                        <div
                          className={`px-5 mb-20 lg:w-1/3 academy__item active`}
                          key={`item=${index}`}
                        >
                          <div className='bg-gray_dark text-white font-serif text-2xl xl:text-4xl pl-10 pr-20 pt-10 pb-20 h-full relative'>
                            <PrismicRichText
                              field={element.node.data.title.richText}
                            />
                            <span className='w-12 h-12 rounded-full bg-gray_light inline absolute bottom-5 right-5'>
                              &nbsp;
                            </span>
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>

            {data.allPrismicAcademy.edges.map((element, index) => {
              return element.node.lang === nodeAttributes.lang ? (
                <div key={`item=${index}`}>
                  <SliceZone
                    sliceZone={element.node.data.body}
                    nodeAttributes={nodeAttributes}
                  />
                </div>
              ) : null;
            })}
          </div>
        );
      }}
    />
  );
};

function truncate(str) {
  return str.length > 100 ? str.substring(0, 97) + "..." : str;
}

export default AcademyRender;
