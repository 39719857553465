import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
//scss
import "../../styles/components/Icons.scss"

const getNextSiblings = (elem) => {
   // create an empty array
   let siblings = [];

   // loop through next siblings until `null`
   while (elem = elem.nextElementSibling) {
      // push sibling to array
      siblings.push(elem);
   }
   return siblings;
};

const getPreviousSiblings = (elem) => {
   // create an empty array
   let siblings = [];

   // loop through previous siblings until `null`
   while (elem = elem.previousElementSibling) {
      // push sibling to array
      siblings.push(elem);
   }
   return siblings;
};

const iconClick = async (e, color) => {
   let nextSiblings = getNextSiblings(e.currentTarget);
   let prevSiblings = getPreviousSiblings(e.currentTarget);

   activateIcon(e.currentTarget, color); //Activate clicked icon

   //Activate all prev icon
   prevSiblings.map(elem => {
      activateIcon(elem, color)
   });

   //Deactivate all prev icon
   nextSiblings.map(elem => {
      disactiveIcon(elem, color)
   })

}
const activateIcon = async (elem, color) => {
   //console.log('Color', elem.dataset.color);
   elem.classList.add('active')

   elem.querySelector('.rounded-full').style.backgroundColor = elem.dataset.color
   elem.querySelector('.icon-text').style.color = elem.dataset.color

}

const disactiveIcon = async (elem, color) => {
   elem.classList.remove('active')
   elem.querySelector('.rounded-full').style.backgroundColor = '#46494C'
   elem.querySelector('.icon-text').style.color = '#46494C'

}
String.prototype.isNumber = function(){return /^\d+$/.test(this);}

const Icons = ({slice}) => (
   <div className="icons-widget pt-20 pb-10">
      <div className="container mx-auto px-5">
         <div className="w-full lg:w-12/12 mx-auto">
            {slice.primary.title.richText && !slice.primary.title_type ? (
               <div className="text-4xl lg:text-5xl font-serif pb-5 mb-10">
                  <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            ) : null}
            {slice.primary.title.richText && slice.primary.title_type ? (
               <div className="text-2xl lg:text-3xl font-serif italic text-center pb-5 mb-10">
                  <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            ) : null}
         </div>
         <span className="sm:w-1/2 lg:w-1/4 lg:w-1/3">&nbsp;</span>
         <div className="w-full lg:w-10/12 mx-auto flex flex-wrap">
            {slice.items.map((item, index) => (
               <div
                  onClick={(e) => item.icon_color ? iconClick(e, item.icon_color) : void(0)}
                  className={`icon w-full sm:w-1/2 lg:w-1/${slice.primary.column_number} flex justify-center relative pb-10 sm:pb-5`} key={index}
                  data-color={item.icon_color}>
                  <div className={`${item.icon_alignment ? 'text-center' : ''}`} style={{maxWidth: '275px'}}>
                     <div className={`rounded-full inline-flex items-center justify-center transition-colors backgroundColor relative ${item.icon_color ? 'cursor-pointer' : ''}`} style={{ backgroundColor: item.icon_color ? '#46494C' : '#fffff', width: '233px', height: '233px'}}>
                        {item.icon_color ? (<span className="pulse-icon"></span>) : null}
                        <Imgix src={item.icon.url} alt={item.icon.alt} width={item.icon.dimensions.width} height={item.icon.dimensions.height}
                               htmlAttributes={{
                                  alt: item.icon.alt ?? ''
                               }}
                        />
                     </div>
                     {item.icon_plus ? (
                        <div className="icon-plus transition-opacity hidden sm:block opacity-0 text-6xl absolute right-1/2 sm:bottom sm:-left-5" style={{color: item.icon_color }}>+</div>
                        ) : null}
                     {item.icon_equal ? (
                        <div className="icon-plus transition-opacity hidden sm:block opacity-0 text-6xl absolute right-1/2 sm:bottom sm:-left-5" style={{color: item.icon_color }}>=</div>
                     ) : null}
                     <div
                        data-sal="fade"
                        data-sal-easing="ease"
                        className="mt-4 relative sm:in">
                        <div className={`${item.title_size ? 'text-5xl' : 'text-2xl'} text-2xl font-serif italic icon-text ${item.icon_alignment ? 'pl-0 text-center' : 'pl-10'}`}>
                           {item.icon_title.text.isNumber() ? (
                                 <VisibilitySensor partialVisibility offset={{ bottom: 0 }}>
                                    {({ isVisible }) => (
                                       <div style={{ height: 50 }}>
                                          {isVisible ? <CountUp separator={'\''} end={item.icon_title.text} duration={1.5} /> : null}
                                       </div>
                                    )}
                                 </VisibilitySensor>
                              ) :
                              (
                                 <PrismicRichText field={item.icon_title.richText}/>
                              )}
                        </div>
                        <div className={`icon-description transition-opacity text-xl mt-5 ${item.icon_alignment ? 'pl-0 text-center' : 'pl-10'} ${item.icon_color ? 'opacity-0': ''}`}>
                           <PrismicRichText field={item.icon_text.richText}/>
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>

   </div>

)

export default Icons
