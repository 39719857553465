import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import Imgix from "react-imgix";
import {useState} from "react";
import {Translations} from "../../Translations";
//Scss
import "../../styles/components/TeamSection.scss"
const responsiveNumberOfCloumns = (parent) => {

   let width = document.body.clientWidth
   //console.log('parentWidth', width);
   if(width > 1500) return 4
   if(width >= 1200) return 3
   if(width >= 992) return 2


   return 2;
}
const TeamSection = ({slice, nodeAttributes}) => {
   //Build filters
   let filtersList = [];

   filtersList[0] = {
      label: Translations.prototype.getTranslations(nodeAttributes.lang).team.categories['tutti'],
      value: 'all',
      isChecked: true
   }
   for (var i = 0; i < slice.items.length; i++) {
      let label_key = slice.items[i].category.replace(/\s/g, '_')
      filtersList[i + 1] = {
         label: Translations.prototype.getTranslations(nodeAttributes.lang).team.categories[label_key],
         value: slice.items[i].category.replace(/\s/g, '_'),
         isChecked: false
      }
   }

   filtersList = filtersList.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === filtersList.findIndex(obj => {
         return JSON.stringify(obj) === _thing;
      });
   });

   // Local state for managing filtering logic
   const [filters, updateFilters] = useState(filtersList);

   const [gridSettings, updateGridSettings] = useState(filtersList);





// Filter change handler
   const onFilter = (event, toFilter, filter) => {
      event.preventDefault()
      let allElements = document.getElementsByClassName(toFilter)
      //console.log(allElements);
      //console.log(filter);
      if(filter === 'all') {
         for(let i = 0; i < allElements.length; i++)
         {
            allElements[i].classList.add('active')
         }
      } else {
         let element = document.getElementsByClassName(filter)
         for(let i = 0; i < allElements.length; i++)
         {
            allElements[i].classList.remove('active')
         }
         for(let i = 0; i < element.length; i++) {
            element[i].classList.add('active')
         }
      }

      // document.getElementsByClassName(toFilter).classList.remove('active')
      // document.querySelector(toFilter + '.' + filter).classList.add('active')
      // if(document.querySelector(toFilter).classList.contains(filter)) {
      //
      // }
   };



   return (
   <div className="team-section">
      <div
         className="container mx-auto py-16">

         <div
            className="filter-container flex flex-wrap justify-center mb-10">
            {filters.map((f, index) => (
               <div key={index} onClick={(e) => onFilter(e, 'team-section__item', f.value)} data-filter={f.value} className="btn-with-gray-after-parent relative inline-block mx-4 mb-5">
                  <button className="relative bg-white text-lg hover:bg-gray_dark text-gray_dark hover:text-white py-2 px-4 border-2 border-gray_dark hover:border-transparent">{f.label}</button>
               </div>
            ))}
         </div>
         <div
            className="grid-parent w-full md:w-10/12 mx-auto flex flex-wrap">

               {/*{cardsDefault.map((card, index) => (*/}
               {/*   <div key={card.id} data-key={index} className={`card ${card.filter[0]}`}>*/}
               {/*      <div className="tags">*/}
               {/*         {card.filter.map(c => (*/}
               {/*            <span key={Math.floor(Math.random() * 10000)}>{c}</span>*/}
               {/*         ))}*/}
               {/*      </div>*/}
               {/*      {card.id}*/}
               {/*   </div>*/}
               {/*))}*/}
               {slice.items.map((card, index) => (
                  <div
                     key={index}
                     className={`team-section__item active w-full md:w-1/2 lg:w-1/3 2xl:w-1/4 mb-10 border-0 text-gray_dark px-5 text-center ${card.category.replace(/\s/g, '_')}`}>
                     {/*{Image}*/}
                     <div
                        className="relative flip-card mb-5">
                        <div
                           className="flip-card-inner aspect-w-1 aspect-h-1">
                           <div
                              className="flip-card-front">
                              <Imgix
                                 className="lazyload mx-auto"
                                 src={card.image.thumbnails.large.url}
                                 width={card.image.thumbnails.large.dimensions.width}
                                 height={card.image.thumbnails.large.dimensions.height}
                                 htmlAttributes={{
                                    alt: card.image.thumbnails.large.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                           </div>
                           <div
                              className="flip-card-back bg-gray_light">
                              <div
                                 className="text-sm font-light h-full flex items-center px-5 people-description">
                                 <PrismicRichText
                                    field={card.description.richText}/>
                              </div>
                           </div>

                        </div>
                     </div>
                     {/*{Name & Surname}*/}
                     <div
                        className="font-serif italic text-lg">
                        <PrismicRichText
                           field={card.name_surname.richText}/>
                     </div>
                     {/*{Role}*/}
                     <div
                        className="text-sm font-light">
                        {card.role}
                     </div>
                  </div>
               ))}


         </div>
      </div>
   </div>
   )
}

export default TeamSection
