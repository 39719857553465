import React, {useState} from 'react'
import { Link, graphql } from 'gatsby'
import LinkResolver from './LinkResolver'
import useScrollHandler from "../hooks/useScrollHandler"
import {LanguageSwitcherLink} from "./LanguageSwitcher";
//Scss
import '../styles/components/Header.scss'
import {Utils} from "../Utilities";


const Header = ({ isHomepage, nodeAttributes, navigation, bgColor = 'bg-white' }) => {
   const [nav, showNav] = useState(false)
   const homepageClass = isHomepage ? 'homepage-header' : ''
   // console.log('NAVIGATION => ', navigation)
   const mainNav = navigation ? navigation.data.nav_item : null
   // if (!navigation) return null
   return (
      <header id="site-header" className={`site-header ${homepageClass} w-full ${useScrollHandler() > 0 ? "site-header--scroll bg-color-" + bgColor.replace('#','').toLowerCase() : ""} ${nav ? "site-navigation--opened" : ""}`}>
         {mainNav ? (
               <div>
                  <div className="container mx-auto flex items-center px-5">

                     <div className="w-2/12">
                        <button className="site-header__toggle flex flex-wrap items-center outline-none" onClick={() => showNav(true)}>
                           <div className="flex flex-wrap site-header__toggle__icon">
                              <span className="site-header__toggle__line mb-3" />
                              <span className="site-header__toggle__line mb-3" />
                              <span className="site-header__toggle__line" />
                           </div>
                           <span className="hidden sm:inline-block text-white ml-3">MENU</span>
                        </button>
                     </div>
                     <div className="w-8/12 flex justify-center">
                        <Link className="py-5" to={Utils.prototype.linkResolver(navigation.data.logo_url)}>
                           <div style={{ backgroundImage: `url(${navigation.data.logo.url})` }} className="site-header__logo bg-no-repeat" />
                        </Link>
                     </div>
                  </div>

                  <nav id="navigation">
                     <div className="container mx-auto px-5 h-full overflow-x-hidden overflow-y-auto">
                        <button className="site-header__toggle flex flex-wrap relative items-center mb-8 mt-8 py-5 px-3 ml-auto" onClick={() => showNav(false)}>
                           <div className="flex flex-wrap site-header__toggle__icon">
                              <span className={`site-header__toggle__line absolute  transition-transform duration-300 delay-700 transform ${nav ? " rotate-45" : ""}`} />
                              <span className={`site-header__toggle__line absolute  transition-transform duration-300 delay-700 transform ${nav ? " -rotate-45" : ""}`} />
                           </div>
                        </button>
                        <p className="text-lg"><a href={`tel: ${navigation.data.telephone_call}`}> {navigation.data.telephone}</a></p>
                        <p className="text-lg"><a href={`mailto:${navigation.data.email}`}>{navigation.data.email}</a></p>
                        <ul className={`mt-5 opacity-0 transition-opacity duration-300 delay-700 ${nav ? "opacity-100" : ""}`}>
                           {mainNav.map((navItem, index) => {
                              return (
                                 <li className={`mb-1 font-serif`} key={`link-${index}`}>
                                    <LinkResolver classes={`py-2 inline-block ${navItem.sub_menu_link ? 'ml-4 text-xl opacity-80' : 'text-2xl'}`} doc={navItem.link} label={navItem.title} />
                                 </li>
                              )
                           })}
                        </ul>
                        <div className={`mb-5 opacity-0 transition-opacity duration-300 delay-700 ${nav ? "opacity-100" : ""}`}>
                           <LanguageSwitcherLink activeDocMeta={nodeAttributes}/>
                        </div>
                     </div>
                  </nav>
               </div>
         ) : null }

      </header>
   )
}

export const query = graphql`
    fragment HeaderQuery on PrismicNavigation {
        _previewable
        url
        type
        tags
        lang
         data {
             logo {
                 url
                 dimensions {
                     height
                     width
                 }
             }
             logo_url {
                 slug
                 url
                 uid
                 tags
                 lang
                 target
                 link_type
                 type
             }
             email
             telephone
             telephone_call
             sub_footer {
                 footer_link_url {
                     url
                 }
                 footer_link_title
             }
             nav_item {
                 title
                 sub_menu_link
                 link {
                     url
                     uid
                     tags
                     lang
                     target
                     link_type
                     type
                 }
             }
         }
     }
`

export default Header
