import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//CSS
import "../../styles/components/ListOfServices.scss"

const MaterialList = ({slice}) => (
   <div className="material_list bg-gray_dark">
      <div className="container mx-auto py-16">
         <div className="lg:w-10/12 mx-auto px-5">
            {slice.primary.headline.richText ? (
               <div className="text-4xl lg:text-5xl font-serif text-white mb-20">
                  <PrismicRichText linkResolver={Utils.prototype.linkResolver} field={slice.primary.headline.richText} />
               </div>
            ) : null}

            {slice.items.map((item, index) => (
               <div className="flex flex-wrap text-white my-20" key={`material_list__${index}`}>
                  <div className="lg:w-1/3">
                     <div className="text-4xl mb-2">
                        <PrismicRichText linkResolver={Utils.prototype.linkResolver} field={item.item_title.richText} />
                     </div>
                     <div className="text-lg">
                        <PrismicRichText linkResolver={Utils.prototype.linkResolver} field={item.item_text.richText} />
                     </div>
                  </div>
                  <div className="lg:w-2/3 flex flex-wrap mt-10 lg:mt-0">
                     <div className="w-1/3 px-5">
                        <Imgix
                           className={`lazyload rounded-full`}
                           src={item.image_one.url}
                           width={item.image_one.dimensions.width}
                           height={item.image_one.dimensions.height}
                           htmlAttributes={{
                              alt: item.image_one.alt ?? ''
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: "data-srcset",
                              sizes: "data-sizes",
                           }}/>
                     </div>
                     <div className="w-1/3 px-5">
                        <Imgix
                           className={`lazyload rounded-full`}
                           src={item.image_two.url}
                           width={item.image_two.dimensions.width}
                           height={item.image_two.dimensions.height}
                           htmlAttributes={{
                              alt: item.image_two.alt ?? ''
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: "data-srcset",
                              sizes: "data-sizes",
                           }}/>
                     </div>
                     <div className="w-1/3 px-5">
                        <Imgix
                           className={`lazyload rounded-full`}
                           src={item.image_three.url}
                           width={item.image_three.dimensions.width}
                           height={item.image_three.dimensions.height}
                           htmlAttributes={{
                              alt: item.image_three.alt ?? ''
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: "data-srcset",
                              sizes: "data-sizes",
                           }}/>
                     </div>
                  </div>
               </div>
            ))}
         </div>


      </div>

   </div>
)

export default MaterialList
