import React, {useState} from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//CSS
import "../../styles/components/ListOfServices.scss"
import FsLightbox
   from "fslightbox-react";
import LinkResolver
   from "../LinkResolver";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Masonry = ({slice}) => {
   // if toggler is updated when lightbox is closed it will open it
   // if toggler is updated when lightbox is opened it will close it
   // Set index of gallery
   const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1
   });

   function openLightboxOnSlide(number) {
      //console.log('OPEN')
      setLightboxController({
         toggler: !lightboxController.toggler,
         slide: number
      });
   }
   // Set sources of gallery
   const sources = slice.items.map((item) =>{
      return item.image.url
   })
   return(
      <div className="masonry_widget">
         {/*START Lightbox*/}
         <FsLightbox
            toggler={lightboxController.toggler}
            sources={sources}
            slide={lightboxController.slide}
         />
         {/*END Lightbox*/}

         {slice.primary.heading.richText || slice.primary.description.richText ? (
               <div className="bg-gray_dark">
                  <div className="container mx-auto py-16">
                     <div className="lg:w-10/12 mx-auto flex flex-wrap">
                        <div className="w-full lg:w-1/2 px-5">
                           <div className="text-4xl text-white mb-5">
                              <PrismicRichText linkResolver={Utils.prototype.linkResolver} field={slice.primary.heading.richText} />
                           </div>
                           <div className="text-lg text-white font-light">
                              <PrismicRichText linkResolver={Utils.prototype.linkResolver} field={slice.primary.description.richText} />
                           </div>
                        </div>
                           {slice.primary.heading.richText || slice.primary.description.richText ? (
                              <div className="order-first lg:order-last lg:w-1/2 px-5 mb-5 text-lg text-white font-light text-right flex items-center justify-end">
                                 <FontAwesomeIcon className="text-6xl mr-2" icon={["fal", "angle-left"]} /> <LinkResolver classes="py-2" doc={slice.primary.link_url} label={slice.primary.link_text} />
                              </div>
                           ) : null}
                     </div>
                  </div>
               </div>
         ) : null}

         <div className="container mx-auto py-16">
            <div className="lg:w-10/12 mx-auto px-5">

               <div className="flex flex-wrap">
                  {slice.items[0] ?
                     (
                        <div
                           onClick={() => openLightboxOnSlide(1)}
                           onKeyPress={() => openLightboxOnSlide(1)}
                           tabIndex="0"
                           role="button"
                           className="w-6/12 px-2 cursor-pointer">
                           <div className="aspect-h-11 relative my-1">
                              <Imgix
                                 className="lazyload object-cover w-full absolute"
                                 src={slice.items[0].image.url}
                                 width={slice.items[0].image.dimensions.width}
                                 height={slice.items[0].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[0].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                           </div>
                        </div>)
                     : null}
                  <div className="w-2/12 px-2 my-2">
                     <div
                        onClick={() => openLightboxOnSlide(2)}
                        onKeyPress={() => openLightboxOnSlide(2)}
                        tabIndex="0"
                        role="button"
                        className="relative h-1/2 mb-1 cursor-pointer">
                        {slice.items[1] ?
                           <Imgix
                              className="lazyload object-cover w-full absolute"
                              src={slice.items[1].image.url}
                              width={slice.items[1].image.dimensions.width}
                              height={slice.items[1].image.dimensions.height}
                              htmlAttributes={{
                                 alt: slice.items[1].image.alt ?? ''
                              }}
                              attributeConfig={{
                                 src: 'data-src',
                                 srcSet: "data-srcset",
                                 sizes: "data-sizes",
                              }}/>
                           : null}
                     </div>
                     <div
                        onClick={() => openLightboxOnSlide(3)}
                        onKeyPress={() => openLightboxOnSlide(3)}
                        tabIndex="0"
                        role="button"
                        className="relative h-1/2 cursor-pointer">
                        {slice.items[2] ?
                           <Imgix
                              className="lazyload object-cover w-full absolute"
                              src={slice.items[2].image.url}
                              width={slice.items[2].image.dimensions.width}
                              height={slice.items[2].image.dimensions.height}
                              htmlAttributes={{
                                 alt: slice.items[2].image.alt ?? ''
                              }}
                              attributeConfig={{
                                 src: 'data-src',
                                 srcSet: "data-srcset",
                                 sizes: "data-sizes",
                              }}/>
                           : null}
                     </div>
                  </div>
                  {slice.items[3] ?(
                        <div
                           onClick={() => openLightboxOnSlide(4)}
                           onKeyPress={() => openLightboxOnSlide(4)}
                           tabIndex="0"
                           role="button"
                           className="w-4/12 px-2 my-2 cursor-pointer">
                           <div className="aspect-w-5 aspect-h-5 relative">
                              <Imgix
                                 className="lazyload object-cover absolute"
                                 src={slice.items[3].image.url}
                                 width={slice.items[3].image.dimensions.width}
                                 height={slice.items[3].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[3].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                           </div>
                        </div>
                     )
                     : null}
                  {slice.items[4] ?(
                        <div
                           onClick={() => openLightboxOnSlide(5)}
                           onKeyPress={() => openLightboxOnSlide(5)}
                           tabIndex="0"
                           role="button"
                           className="w-4/12 px-2 my-2 cursor-pointer">
                           <div className="aspect-w-5 aspect-h-5 relative">
                              <Imgix
                                 className="lazyload object-cover absolute"
                                 src={slice.items[4].image.url}
                                 width={slice.items[4].image.dimensions.width}
                                 height={slice.items[4].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[4].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                              />
                           </div>
                        </div>
                     )
                     : null}
                  {slice.items[5] ?(
                        <div
                           onClick={() => openLightboxOnSlide(6)}
                           onKeyPress={() => openLightboxOnSlide(6)}
                           tabIndex="0"
                           role="button"
                           className="w-4/12 px-2 my-2 cursor-pointer">
                           <div className="aspect-w-5 aspect-h-5 relative">
                              <Imgix
                                 className="lazyload object-cover absolute"
                                 src={slice.items[5].image.url}
                                 width={slice.items[5].image.dimensions.width}
                                 height={slice.items[5].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[5].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                              />
                           </div>
                        </div>
                     )
                     : null}
                  {slice.items[6] ?(
                        <div
                           onClick={() => openLightboxOnSlide(6)}
                           onKeyPress={() => openLightboxOnSlide(6)}
                           tabIndex="0"
                           role="button"
                           className="w-4/12 px-2 my-2 cursor-pointer">
                           <div className="aspect-w-5 aspect-h-5 relative">
                              <Imgix
                                 className="lazyload object-cover absolute"
                                 src={slice.items[6].image.url}
                                 width={slice.items[6].image.dimensions.width}
                                 height={slice.items[6].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[6].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                              />
                           </div>
                        </div>
                     )
                     : null}
                  {slice.items[7] ?(
                        <div
                           onClick={() => openLightboxOnSlide(8)}
                           onKeyPress={() => openLightboxOnSlide(8)}
                           tabIndex="0"
                           role="button"
                           className="w-6/12 px-2 my-2 cursor-pointer">
                           <div className="aspect-w-7 aspect-h-5 relative">
                              <Imgix
                                 className="lazyload object-cover absolute"
                                 src={slice.items[7].image.url}
                                 width={slice.items[7].image.dimensions.width}
                                 height={slice.items[7].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[7].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                           </div>
                        </div>
                     )
                     : null}
                  {slice.items[8] ?(
                        <div
                           onClick={() => openLightboxOnSlide(9)}
                           onKeyPress={() => openLightboxOnSlide(9)}
                           tabIndex="0"
                           role="button"
                           className="w-6/12 px-2 my-2 cursor-pointer">
                           <div className="aspect-w-7 aspect-h-5 relative">
                              <Imgix
                                 className="lazyload object-cover absolute"
                                 src={slice.items[8].image.url}
                                 width={slice.items[8].image.dimensions.width}
                                 height={slice.items[8].image.dimensions.height}
                                 htmlAttributes={{
                                    alt: slice.items[8].image.alt ?? ''
                                 }}
                                 attributeConfig={{
                                    src: 'data-src',
                                    srcSet: "data-srcset",
                                    sizes: "data-sizes",
                                 }}/>
                           </div>
                        </div>
                     )
                     : null}
               </div>
            </div>


         </div>

      </div>
   )
}

export default Masonry
