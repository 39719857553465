import React from 'react'
import LinkResolver
   from "./LinkResolver";

export const LanguageSwitcherLink = ({ activeDocMeta }) => {
   const currentLang = activeDocMeta.lang
   const currentLangOption = (
      <li className="opacity-50 py-3 px-3">
         {currentLang.slice(0, 2).toUpperCase()}
      </li>
   )

   const alternateLangOptions = activeDocMeta.alternate_languages.map((altLang, index) => (
      <li key={`alt-lang-${index}`}>
         <span className="mx-2">/</span><LinkResolver classes="py-3 px-3" doc={altLang} label={`${altLang.lang.slice(0, 2).toUpperCase()}`} />
      </li>
   ))

   return (
      <ul className="flex font-bold items-center">
         {currentLangOption}
         {alternateLangOptions}
      </ul>
   )
}
