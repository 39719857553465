import React, {useState} from 'react';
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import Imgix from "react-imgix";
import ReCAPTCHA
   from "react-google-recaptcha";

const ContestParticipation = ({slice, tags = 'contest;'}) => {
   const [status, setStatus] = useState(null);
   const [message, setMessage] = useState(null);
   const [serverResponse, setServerResponse] = React.useState(``)
   const [captcha, setCaptcha] = React.useState(``)
   let recaptchaRef = React.useRef();
   //Set Default forms value
   const [value, setValue] = React.useState({
      tags: tags || ''
   })

   function captchaValue(response) {
      setCaptcha(response);
      let fakeEvent = {
         target: {
            id: 'captcha',
            value: response
         }
      }
      handleChange(fakeEvent)
   }

   const handleSubmit = async (e) => {
      e.preventDefault();
      if(captcha) {
         setStatus('waiting')
         setMessage('Stiamo elaborando la tua richiesta...')

         const response = await window
            .fetch(`/api/newsletter`, {
               method: `POST`,
               headers: {
                  "content-type": "application/json",
               },
               body: JSON.stringify(value)
            })
            .then(res => res.json())

         setServerResponse(response)
         setStatus(response.status)

         //Push GA event
         if(response.status === 200 && window.dataLayer) {

            window.dataLayer.push({
               'event': 'submit',
               'event_category': 'form_submission',
               'event_label': 'newsletter_registration',
               'value': 1
            })

            window.dataLayer.push({
               'event': 'newsletter_registration',
            })
         }
      } else {
         setCaptcha(null)
      }
   };

   // Listen to form changes and save them.
   function handleChange(e) {
      value[e.target.id] = e.target.value
      setServerResponse(``)
      //console.log(value)

      setValue({ ...value })
   }

   return (
      <div className="container mx-auto px-5 py-40">
         <div className="w-full lg:w-10/12 mx-auto">

            <div className="flex flex-wrap">
               <div className="w-full md:w-6/12 lg:w-1/2 order-1 lg:order-0">
                  <div className="text-4xl lg:text-5xl font-serif pb-5 mb-5">
                     {slice.primary.title.richText ? (

                        <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver}/>
                     ): null}
                  </div>
                  <form onSubmit={handleSubmit} name="subscribeForm" method="POST" id="subscribe-form" className="subscribe-form">
                     <div className="flex flex-wrap -mx-5">
                        <div className="w-full lg:w-8/12 px-5 mb-10">
                           <label className="hidden" htmlFor="firstName">
                              <span className="screen-reader-text">Nome</span>
                           </label>
                           <input onChange={handleChange} value={value[`first_name`] || ``} className="w-full placeholder-gray border-2 py-6 px-6 border-gray_dark focus:border-gray focus:ring-0" type="text" name="first_name" required id="first_name" placeholder="Nome"/>
                        </div>
                        <div className="w-full lg:w-8/12 px-5 mb-10">
                           <label className="hidden" htmlFor="lastName">
                              <span className="screen-reader-text">Cognome</span>
                           </label>
                           <input onChange={handleChange} value={value[`last_name`] || ``} className="w-full placeholder-gray border-2 py-6 px-6 border-gray_dark focus:border-gray focus:ring-0" type="text" name="last_name" required id="last_name" placeholder="Cognome"/>
                        </div>
                        <div className="w-full lg:w-8/12 px-5 mb-5">
                           <label className="hidden" htmlFor="email">
                              <span className="screen-reader-text">Indirizzo email</span>
                           </label>
                           <input onChange={handleChange} value={value[`email`] || ``} className="w-full placeholder-gray border-2 py-6 px-6 border-gray_dark focus:border-gray focus:ring-0" type="email" required name="email" id="email" placeholder="Indirizzo email"/>
                        </div>
                        <div className="w-full lg:w-8/12 px-5 mb-5">
                           <label htmlFor="privacy_agree">
                              <input className="mr-2" value="1" type="checkbox" required name="contest_agree" id="privacy_agree"/>
                              Ho letto e acconsento al trattamento dei miei dati secondo la <a className="underline" target="_blank" href="https://www.iubenda.com/privacy-policy/56313002">privacy policy</a>
                           </label>
                        </div>
                        <div className="w-full lg:w-8/12 px-5 mb-5">
                           <label htmlFor="contest_agree">
                              <input className="mr-2" value="1" type="checkbox" required name="contest_agree" id="contest_agree"/>
                              Ho letto e acconsento al <a className="underline" target="_blank" href="https://mtmk.ams3.digitaloceanspaces.com/customers/newline/new-line-regolamento-concorso-15-12__31-01.pdf">regolamento del concorso</a> e acconsento ad essere ricontattato.
                           </label>
                        </div>
                        <div className="w-full lg:w-8/12 px-5 mb-5">
                           <ReCAPTCHA
                              sitekey={process.env.RECAPTCHA_KEY}
                              onChange={captchaValue}
                           />
                           {captcha == null && <p className="inline-block text-sm mb-5 py-2 px-3 bg-red text-white">Il campo "non sono un robot" è obbligatorio</p>}

                        </div>
                        <div className="w-full lg:w-8/12 px-5 mb-5">
                           {status === 'waiting' && <p className="inline-block text-sm mb-5 py-2 px-3 bg-green text-white">{message}</p>}
                           {status === 200 && <p className="inline-block text-sm mb-5 py-2 px-3 bg-green text-white">{serverResponse.message}</p>}
                           {(status === 500 || status === 400) && <p className="inline-block text-sm mb-5 py-2 px-3 bg-red text-white">{serverResponse.message}</p>}
                        </div>
                        <div className="px-5">
                           <div className="btn-with-gray-after-parent relative">
                              {serverResponse.status == null ?
                                 (
                                    <button type="submit" className="relative bg-white text-xl hover:bg-gray_dark text-gray_dark font-semibold hover:text-white py-4 px-4 border-4 border-gray_dark hover:border-transparent px-20 focus:ring-0">
                                       invia
                                    </button>
                                 ) : null
                              }
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="w-full ml-auto mb-10 md:w-5/12 lg:w-1/2 order-0 md:order-1">
                  <Imgix
                     className="lazyload"
                     src={slice.primary.image.url}
                     width={slice.primary.image.dimensions.width}
                     height={slice.primary.image.dimensions.height}
                     htmlAttributes={{
                        alt: slice.primary.image.alt ?? ''
                     }}
                     attributeConfig={{
                        src: 'data-src',
                        srcSet: "data-srcset",
                        sizes: "data-sizes",
                     }}/>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ContestParticipation
