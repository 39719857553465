import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//scss

const List = ({slice}) => (
   <div className="list pt-20 pb-20">
      <div className="container mx-auto px-5">
         <div className="w-full lg:w-12/12 mx-auto">
            {slice.primary.title.richText ? (
               <div className="text-4xl lg:text-5xl font-serif pb-5 mb-10">
                  <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            ) : null}
         </div>
         <div className="w-full md:w-10/12 lg:w-10/12 mx-auto">
            {slice.items.map((item, index) => (
               <div data-sal="fade-up" data-sal-delay={100 * index} className="mx-2" key={`list=${index}`}>
                  <h4 className="text-2xl before:dot-list flex items-center mb-5">{item.list_text}</h4>
               </div>
            ))}
         </div>
      </div>

   </div>

)

export default List
