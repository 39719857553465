module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"23417a6eed75efbc79a37c6f93aae238"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 100%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"new-line","accessToken":"MC5ZTU5GWkJNQUFDSUFiNlRp.77-977-9Ze-_ve-_ve-_vUbvv73vv71M77-9eO-_ve-_vX0y77-977-9au-_ve-_vV4U77-977-9G--_vWxy77-977-977-9","imageImgixParams":{"auto":"compress,format","fit":"max","q":100},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"promptForAccessToken":true,"apiEndpoint":"https://new-line.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
