import React, {useState} from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {StaticQuery, graphql, Link} from 'gatsby';
import LinkResolver
   from "../LinkResolver";
import {Utils} from "../../Utilities";
//css
import "../../styles/components/AllNews.scss"


const AllNews = ({slice, nodeAttributes}) => {
   let filtersList = [];
   const [filters, updateFilters] = useState(filtersList);

   return (<StaticQuery
      query={graphql`
      {
        allPrismicNews(sort: {fields: data___date, order: DESC}) {
          edges {
            node {
              id
              uid
              type
              lang
              url
              tags
              data {
                title {
                  richText
                  text
                }
                image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
                date
                meta_title
                meta_description
                body {
                  richText
                  text
                }
              }
            }
          }
        }
      }
    `}
      render={data => {
         //Build filters

         filtersList[0] = {
            label: 'tutti',
            value: 'all',
            isChecked: true
         }
         for (var i = 0; i < data.allPrismicNews.edges.length; i++) {
            if(data.allPrismicNews.edges[i].node.tags.length > 0) // Check if news has tag
            {
               filtersList[i + 1] = {
                  label: data.allPrismicNews.edges[i].node.tags[0],
                  value: data.allPrismicNews.edges[i].node.tags[0].replace(/\s/g, ''),
                  isChecked: false
               }
            }
         }

         filtersList = filtersList.filter((thing, index) => {
            const _thing = JSON.stringify(thing);
            return index === filtersList.findIndex(obj => {
               return JSON.stringify(obj) === _thing;
            });
         });


         function tagClasses(values) {
            let classes = ''
            //console.log('values', values)
            values.map((value, index) => {
               classes = classes + ' ' + value.replace(/\s/g, '');
            })
            return classes;
         }

         // Filter change handler
         const onFilter = (event, toFilter, filter) => {
            event.preventDefault()
            let allElements = document.getElementsByClassName(toFilter)
            //console.log(allElements);
            //console.log(filter);
            if(filter === 'all') {
               for(let i = 0; i < allElements.length; i++)
               {
                  allElements[i].classList.add('active')
               }
            } else {
               let element = document.getElementsByClassName(filter)
               for(let i = 0; i < allElements.length; i++)
               {
                  allElements[i].classList.remove('active')
               }
               for(let i = 0; i < element.length; i++) {
                  element[i].classList.add('active')
               }
            }

            // document.getElementsByClassName(toFilter).classList.remove('active')
            // document.querySelector(toFilter + '.' + filter).classList.add('active')
            // if(document.querySelector(toFilter).classList.contains(filter)) {
            //
            // }
         };

         function triggerFilter() {
            document.querySelector('.filter-container').classList.toggle('disable')
         }

         //console.log('uniqueArray = ', filtersList);
        return ( <div
            className="news pt-20 all-news">

            <div
               className="container mx-auto px-5">
               <div
                  className="w-full lg:w-10/12 mx-auto">
                  <div
                     className="text-4xl lg:text-5xl font-serif pb-5 inline-block">
                     <PrismicRichText
                        field={slice.primary.title.richText}
                        linkResolver={Utils.prototype.linkResolver}/>
                  </div>
               </div>
            </div>
           <div className="bg-gray_light py-20">
              <div className="container mx-auto px-5">
                 <div className="w-full lg:w-10/12 mx-auto">
                    {/*START Filters*/}
                    <div className="-mx-5">
                       <div className="btn-with-gray-after-parent relative inline-block mx-4 mb-10">
                          <button onClick={triggerFilter} className="relative bg-gray_light text-lg hover:bg-gray_dark text-gray_dark hover:text-white py-2 px-10 border-2 border-gray_dark hover:border-transparent">mostra filtri</button>
                       </div>
                    </div>
                    <div
                       className="filter-container flex flex-wrap mb-10 -mx-5 overflow-hidden disable">
                       {filtersList.map((f, index) => (
                          <div key={index} onClick={(e) => onFilter(e, 'news__item', f.value)} data-filter={f.value} className="btn-with-gray-after-parent relative inline-block mx-4 mb-5">
                             <button className="relative bg-gray_light text-lg hover:bg-gray_dark text-gray_dark hover:text-white py-2 px-10 border-2 border-gray_dark hover:border-transparent">{f.label}</button>
                          </div>
                       ))}
                    </div>
                    {/*END Filters*/}
                    <div
                       className="flex flex-wrap -mx-5">
                       {data.allPrismicNews.edges.map((element, index) => {
                          return element.node.lang === nodeAttributes.lang ? (
                             <div
                                className={`px-5 mb-20 lg:w-1/3 news__item active ${tagClasses(element.node.tags)}`}
                                key={`item=${index}`}>
                                <Link
                                   to={Utils.prototype.linkResolver(element.node)}>
                                   <Imgix
                                      className="lazyload"
                                      src={element.node.data.image.url}
                                      width={element.node.data.image.dimensions.width}
                                      height={element.node.data.image.dimensions.height}
                                      htmlAttributes={{
                                         alt: element.node.data.image.alt ?? ''
                                      }}
                                      attributeConfig={{
                                         src: 'data-src',
                                         srcSet: "data-srcset",
                                         sizes: "data-sizes",
                                      }}/>
                                </Link>
                                <p className="font-serif text-sm mt-5 italic">{element.node.data.date}</p>
                                <div
                                   className="text-xl lg:text-2xl font-bold">
                                   <LinkResolver
                                      doc={element.node}
                                      label={element.node.data.title.text}/>
                                </div>
                                {truncate(element.node.data.body.text)}
                                <Link
                                   to={Utils.prototype.linkResolver(element.node)}>
                                 <span
                                    className="font-serif block mt-4">leggi di più ></span>
                                </Link>
                             </div>
                          ) : null
                       })}

                    </div>


                 </div>
              </div>
           </div>
         </div>
        )}}
   />)
}

function truncate(str) {
   return str.length > 100 ? str.substring(0, 97) + "..." : str;
}

export default AllNews
