import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import ContactForm
   from "../ContactForm";
import {Translations} from "../../Translations";
//scss

const ContactFormSection = ({slice, nodeAttributes}) => (
   <div className="contact_form_section py-20">
      <div className="container mx-auto">
         <div className="lg:w-10/12 mx-auto">
            <div className="text-4xl lg:text-5xl font-serif px-5 mb-10">
               <PrismicRichText field={slice.primary.title.richText} />
            </div>
            <div className="lg:w-9/12 xl:w-8/12">
               <ContactForm additionalFields={[]} nodeAttributes={nodeAttributes} subject={Translations.prototype.getTranslations(nodeAttributes.lang).contact_form.contact_subject} />
            </div>
         </div>
      </div>
   </div>

)

export default ContactFormSection
