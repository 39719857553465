import React from 'react';
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import LinkResolver from "../LinkResolver";
import 'lazysizes';

const IntroSecondVersion = ({slice}) => (
   <div className="intro-second-version">
      <div className="container mx-auto py-16 mb-5">
         <div className="lg:w-10/12 mx-auto">
            <div className="flex flex-wrap">
               <div className="w-full xl:w-6/12 px-5">
                  <div className="text-xl font-light">
                     {/*<Imgix*/}
                     {/*   className="mb-5 lazyload"*/}
                     {/*   src={slice.primary.image_left.url}*/}
                     {/*   alt={slice.primary.image_left.alt}*/}
                     {/*   width={slice.primary.image_left.dimensions.width}*/}
                     {/*   height={slice.primary.image_left.dimensions.height}*/}
                     {/*   attributeConfig={{*/}
                     {/*      src: 'data-src',*/}
                     {/*      srcSet: "data-srcset",*/}
                     {/*      sizes: "data-sizes",*/}
                     {/*   }}*/}
                     {/*/>*/}
                     <PrismicRichText field={slice.primary.description.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>
                  <div className="text-center">
                     <div className="btn-with-gray-after-parent relative inline-block mt-10">
                        <LinkResolver doc={slice.primary.cta_link} classes={Utils.prototype.btnClass('my-btn-gray_dark-outline') + ' block text-center px-10'} label={slice.primary.cta_text} />
                     </div>
                  </div>
               </div>
               {/*Images collage*/}
               <div className="w-full relative lg:ml-auto mt-10 xl:mt-0 xl:ml-auto xl:w-5/12 bg-gray-500 px-5">
                     {/*{slice.primary.intro_image_1.url}*/}
                  <Imgix className="lazyload mx-auto"
                         src={slice.primary.image_right.url}
                         width={slice.primary.image_right.dimensions.width}
                         height={slice.primary.image_right.dimensions.height}
                         htmlAttributes={{
                            alt: slice.primary.image_right.alt ?? ''
                         }}
                         attributeConfig={{
                            src: 'data-src',
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                         }}/>
               </div>
            </div>
         </div>
      </div>
   </div>
)

export default IntroSecondVersion
