import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
const IntroThirdVersion = ({slice}) => (
   <div>
      <div className="container mx-auto py-16">
         <div className="w-full lg:w-8/12 xxl:w-1/2 px-5 mx-auto">
            <div className="text-3xl italic font-serif text-center leading-relaxed lg:px-10 xl:px-20 text-gray_dark">
               <PrismicRichText field={slice.primary.description.richText} linkResolver={Utils.prototype.linkResolver} />
            </div>
         </div>
      </div>
   </div>
)

export default IntroThirdVersion
