import JSONDataIt from "./translations/it.json"
import JSONDataEn from "./translations/en.json"
import JSONDataDe from "./translations/de.json"

export class Translations {
   getTranslations(lang = 'it-it') {
      if(lang === 'it-it') return JSONDataIt
      else if(lang === 'en-gb') return JSONDataEn
      else if(lang === 'de-de') return JSONDataDe
   }
}
