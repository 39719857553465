import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
const Intro = ({slice}) => (
   <div>
      <div className="container mx-auto py-16">
         <div className="lg:w-10/12 mx-auto">
            <div className="flex flex-wrap">
               <div className="w-full flex flex-wrap lg:w-7/12 items-center px-5">
                  <div
                     className="text-2xl italic font-serif text-center lg:px-10 xl:px-20 text-gray_dark">
                     <PrismicRichText field={slice.primary.intro_body.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>

                  <div className="flex mt-8">
                     {slice.items.map((item, index) => (
                        <div
                           data-sal="fade"
                           data-sal-easing="ease"
                           data-sal-delay={(index + 1) * 100}
                           className="mx-2" key={`item=${index}`}>
                           <Imgix
                              className="lazyload"
                              src={item.intro_logo_image.url}
                              width={item.intro_logo_image.dimensions.width}
                              height={item.intro_logo_image.dimensions.height}
                              htmlAttributes={{
                                 alt: item.intro_logo_image.alt ?? ''
                              }}
                              attributeConfig={{
                                 src: 'data-src',
                                 srcSet: "data-srcset",
                                 sizes: "data-sizes",
                              }}/>
                        </div>
                     ))}
                  </div>

               </div>
               <div
                  data-sal="fade"
                  data-sal-easing="ease"
                  data-sal-delay="600"
                  className="w-full lg:ml-auto mt-10 lg:mt-0 lg:w-5/12 bg-gray-500 px-5">
                  {/*{slice.primary.intro_image_1.url}*/}
                  <Imgix className="lazyload mx-auto"
                         src={slice.primary.intro_image_1.url}
                         width={slice.primary.intro_image_1.dimensions.width}
                         height={slice.primary.intro_image_1.dimensions.height}
                         htmlAttributes={{
                            alt: slice.primary.intro_image_1.alt ?? ''
                         }}
                         attributeConfig={{
                            src: 'data-src',
                            srcSet: "data-srcset",
                            sizes: "data-sizes",
                         }}/>
               </div>
            </div>
         </div>
      </div>
   </div>
)

export default Intro
