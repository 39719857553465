import React from 'react'
import Imgix from "react-imgix";
import {StaticQuery, graphql} from 'gatsby';
import Carousel from 'react-multi-carousel'
//css
import "react-multi-carousel/lib/styles.css";
import LinkResolver
   from "../LinkResolver";
import {Utils} from "../../Utilities";
import {StaticImage} from "gatsby-plugin-image";
const responsive = {
   all: {
      breakpoint: { max: 99999, min: 0 },
      items: 1
   }
};

const RealEstateObjectsList = ({slice, nodeAttributes}) => (
   <StaticQuery
      query={graphql`
      {
        allPrismicRealEstateObject(sort: {fields: first_publication_date, order: DESC}) {
          edges {
            node {
              id
              uid
              type
              lang
              url
              data {
               abitability
                bedrooms
                bathrooms
                parking
                object_type
                body {
                  ... on PrismicRealEstateObjectDataBodyHero {
                    id
                    primary {
                      title {
                        richText
                        text
                      }
                      image {
                        alt
                        dimensions {
                          height
                          width
                        }
                        url
                      }
                    }
                    slice_type
                  }
                }
              }
            }
          }
        }
      }
    `}
      render={data => (

            <div className="real-estate-object bg-gray_dark">
               <Carousel  className="-mx-5 mt-5" responsive={responsive}>
                  {data.allPrismicRealEstateObject.edges.map((element, index) => {
                     return element.node.lang ===  nodeAttributes.lang ? (
                        <div className="px-5" key={`item=${index}`}>
                           <Imgix
                              autoPlay
                              autoPlaySpeed={5000}
                              className="lazyload mx-auto"
                              src={element.node.data.body[0].primary.image.url}
                              width={element.node.data.body[0].primary.image.dimensions.width}
                              height={element.node.data.body[0].primary.image.dimensions.height}
                              htmlAttributes={{
                                 alt: element.node.data.body[0].primary.image.alt ?? ''
                              }}
                              attributeConfig={{
                                 src: 'data-src',
                                 srcSet: "data-srcset",
                                 sizes: "data-sizes",
                              }}/>
                           <div className="container mx-auto mt-10">
                              <div className="lg:w-10/12 items-center flex flex-wrap mx-auto">
                                 <div className="w-full px-5">
                                    <h3 className="text-white text-4xl font-sans">{element.node.data.body[0].primary.title.text}</h3>
                                 </div>
                                 <div className="lg:w-8/12 px-5">
                                    <div className="flex flex-wrap py-10 text-white">
                                       {element.node.data.abitability ? (
                                          <div className="flex items-center justify-center pr-5 mr-5 lg:mr-10 border-r-0 2xl:border-r-2 border-white last:border-r-0 mb-10">
                                             <StaticImage
                                                src='../../images/icon_space_white.svg'
                                                width={70}
                                                height={70}
                                                quality={100}
                                                formats={["AUTO", "WEBP", "AVIF"]}
                                                alt="Spazio abitabile"
                                             />
                                             <div className="text-2xl px-4">
                                                {element.node.data.abitability} m<sup>2</sup>
                                             </div>
                                          </div>
                                       ) : null}

                                       {element.node.data.bedrooms ? (
                                          <div className="flex items-center pr-5 mr-5 lg:mr-10 border-r-0 2xl:border-r-2 border-white last:border-r-0 mb-10">
                                             <StaticImage
                                                src='../../images/icon_bedroom_white.svg'
                                                width={70}
                                                height={70}
                                                quality={100}
                                                formats={["AUTO", "WEBP", "AVIF"]}
                                                alt="Spazio abitabile"
                                             />
                                             <div className="text-2xl px-4">{element.node.data.bedrooms}</div>
                                          </div>
                                       ): null }

                                       {element.node.data.bathrooms ? (
                                          <div className="flex items-center pr-5 mr-5 lg:mr-10 border-r-0 2xl:border-r-2 border-white last:border-r-0 mb-10">
                                             <StaticImage
                                                src='../../images/icon_bathroom_white.svg'
                                                width={70}
                                                height={70}
                                                quality={100}
                                                formats={["AUTO", "WEBP", "AVIF"]}
                                                alt="Spazio abitabile"
                                             />
                                             <div className="text-2xl px-4">
                                                {element.node.data.bathrooms}
                                             </div>
                                          </div>
                                       ): null }

                                       {element.node.data.parking ? (
                                          <div className="flex items-center pr-5 mr-5 lg:mr-10 border-r-0 2xl:border-r-2 border-white last:border-r-0 mb-10">
                                             <StaticImage
                                                src='../../images/icon_parking_white.svg'
                                                width={95}
                                                height={70}
                                                quality={100}
                                                formats={["AUTO", "WEBP", "AVIF"]}
                                                alt="Spazio abitabile"
                                             />
                                             <div className="text-2xl px-4">
                                                {element.node.data.parking}</div>
                                          </div>
                                       ): null }
                                    </div>
                                 </div>
                                 <div className="lg:w-4/12 px-5">
                                    <div className="btn-with-white-after-parent relative inline-block mb-10">
                                       <LinkResolver doc={element.node} classes={Utils.prototype.btnClass('my-btn-white-outline') + ' bg-gray_dark block text-center px-20'} label='dettagli' />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ): null})}

               </Carousel>
            </div>
      )}
   />
)

export default RealEstateObjectsList
