import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//scss

const TextBanner = ({slice}) => (
   <div className="text-banner py-20" style={{backgroundColor: slice.primary.bg_color}}>
      <div className="container mx-auto">
         <div className={`w-full ${slice.primary.width === 'full' ? 'lg:w-12/12' : 'lg:w-10/12'} mx-auto ${slice.primary.banner_type ? 'flex flex-wrap items-center': ''}`}>
            {slice.primary.title.richText.length > 0 ? (
               <div className={`text-4xl lg:text-5xl font-serif leading-normal lg:leading-normal pb-5 px-5 ${slice.primary.banner_type && slice.primary.text.richText.length > 0 ? 'lg:w-4/12 text-center': 'w-full'}`} style={{color: slice.primary.text_color}}>
                  <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            ) : null}
            {slice.primary.text.richText.length > 0 ? (
                  <div className={`text-xl font-light w-full md:w-11/12 lg:w-7/12 xl:w-5/12 px-5 ${slice.primary.banner_type ? 'lg:w-8/12 xl:w-6/12' : ''}`} style={{color: slice.primary.text_color}}>
                     <PrismicRichText field={slice.primary.text.richText} linkResolver={Utils.prototype.linkResolver} />
                  </div>
            ) : null}
         </div>
      </div>

   </div>

)

export default TextBanner
