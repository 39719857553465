import React from "react";
import {Utils} from "../Utilities"
import {Link} from "gatsby"
import {PrismicRichText} from "@prismicio/react";
import LinkResolver
   from "./LinkResolver";

const defaultText = [{
      spans: [],
      text: "Compila il form e \nrichiedi una consulenza \nper la tua ristrutturazione.",
      type: "paragraph"
   }
]

const ContactCard = ({bgClass = 'bg-white', textClass = 'text-gray_dark', btnClass = 'btn-gray', cardText= defaultText, cardCtaText = null, cardLink = null }) => (
   <div className={bgClass.replace('#', '').toLowerCase() + " p-10"} data-sal="fade-in"
        data-sal-delay="300"
        data-sal-easing="ease">
      <div className={`font-sans font-semibold text-md md:text-xl mb-5 ${textClass.replace('#', '')}`}>
         <PrismicRichText field={cardText} />
         <span className="hidden bg-color-1fb7ce bg-color-e77b48 bg-color-6c8087 text-visible-1fb7ce text-visible-e77b48 text-visible-6c8087">&nbsp;</span>
      </div>
      {cardLink.type ? (
         btnClass !== 'btn-gray' ? (
            <div className="btn-with-white-after-parent relative flex relative">
               <LinkResolver classes={Utils.prototype.btnClass('my-btn-white-outline') + ' block text-center w-full ' + bgClass.replace('#', '')} doc={cardLink} label={cardCtaText} />
               {/*<Link className={Utils.prototype.btnClass('my-btn-white-outline') + ' block text-center w-full ' + bgClass.replace('#', '')} to='/home'>{cardCtaText}</Link>*/}
            </div>
         ): (
            <div className="btn-with-gray-after-parent relative">
               <LinkResolver classes={Utils.prototype.btnClass('my-btn-gray_dark-outline') + ' block text-center w-full ' + bgClass.replace('#', '')} doc={cardLink} label={cardCtaText} />
               {/*<Link to="/home" className={Utils.prototype.btnClass('my-btn-gray_dark-outline') + ' block text-center w-full'}>{cardCtaText}</Link>*/}
            </div>
         )
      ) : null}
   </div>
)


export default ContactCard
