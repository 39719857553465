import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
//scss

const TwoColumnsText = ({slice}) => (
   <div className="two-column-text py-20">
      <div className="container mx-auto">
         <div className="lg:w-10/12 mx-auto flex flex-wrap items-stretch self-stretch">
            {slice.items.map((item, index) => (
               <div key={index} className={`w-full flex flex-wrap content-around px-5 ${item.font_type === 'serif' ? 'font-serif text-2xl italic leading-relaxed' : 'font-sans text-xl'} ${slice.primary.number_of_columns === '2' ? 'md:w-1/2' : ''} ${slice.primary.number_of_columns === '3' ? 'lg:w-1/3' : ''}`}>
                  <div>
                     <PrismicRichText field={item.text.richText} />
                  </div>
                  <div className="mt-10">
                     {item.image.url ? (
                        <Imgix
                           className={`lazyload mt-auto`}
                           src={item.image.url}
                           width={item.image.dimensions.width}
                           height={item.image.dimensions.height}
                           htmlAttributes={{
                              alt: item.image.alt ?? 'foto'
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: 'data-srcset',
                              sizes: 'data-sizes',
                           }}
                        />
                     ): null}
                     {item.image_description ? (
                           <p className="font-serif text-lg mt-5">
                              {item.image_description}
                           </p>
                        )
                        : null}
                  </div>
               </div>
            ))}
         </div>
      </div>

   </div>

)

export default TwoColumnsText
