import React, {useState, useEffect, useRef} from 'react';
import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NewsletterSubscription
   from "./slices/NewsletterSubscription";
import NewsletterForm
   from "./NewsletterForm";

const NewsletterModal = () => {
   const [open, setOpen] = useState(false)

   const cancelButtonRef = useRef(null)
   const cookies = new Cookies();

   useEffect(()=>{
      setTimeout(() => {
         if (cookies.get('newsletter-modal') || cookies.get('newsletter-subscribed')) {
            setOpen(false); //Modal does not open if cookie exists
         } else if (!cookies.get('newsletter-modal')) {
            cookies.set('newsletter-modal', 'true', {
               path: '/',
               maxAge: 10
            });
            setOpen(true); //Creates a cookie and shows modal.
         }
      }, 5000);
   },[])

   function setCookies() {
      cookies.set('newsletter-modal', 'true', {
         path: '/',
         maxAge: 172800
      });
      setOpen(false);
   }

   if(open) {
      return (
         <div className="relative z-10 modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div className="fixed inset-0 bg-green bg-opacity-30 transition-opacity"></div>

            <div className="fixed z-30 inset-0 overflow-y-auto">
               <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">

                  <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 max-w-3xl sm:w-full">

                     <div className="flex w-full flex-wrap">
                        <div className="w-full md:w-1/2 px-8 py-8 order-1 md:order-0">
                           <h4 className="text-4xl font-serif">Non perderti le novità, iscriviti alla newsletter</h4>

                           <NewsletterForm classes="py-0 px-0 mx-auto md:-mx-5" />
                        </div>
                        <div className="w-full md:w-1/2 bg-cover bg-center relative order-0 md:order-1 aspect-video h-96 md:h-auto" style={{ backgroundImage: 'url("https://images.prismic.io/new-line/82514401-637b-49f5-b2b6-c3d7f74fa900_modale_newsletter_GANDRIA_Cammino_34.jpeg?auto=compress,format")'}}>
                           <FontAwesomeIcon
                              className={`absolute text-3xl right-0 mr-3 mt-2 text-white hover:cursor-pointer`} icon={["fal", 'times']}
                              onClick={() => setCookies()}
                           />

                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      )
   }
   else {
      return null;
   }
}

export default NewsletterModal
