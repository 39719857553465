import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
//Scss
import '../../styles/components/TimeLine.scss'

const TimeLine = ({slice}) => (
   <div className="time-line">
      <div className="container mx-auto px-5 pt-20">
         <div className="w-full mx-auto">
            <div className="text-4xl lg:text-5xl font-serif leading-normal lg:leading-normal pb-5">
               <PrismicRichText field={slice.primary.title.richText} linkResolver={Utils.prototype.linkResolver} />
            </div>
         </div>
         <div className="w-full lg:w-10/12 xl:w-7/12 mx-auto mt-10">
            {slice.items.map((item, index) => (
               <div key={`time_line_${index}`} data-sal="fade" data-sal-easing="ease" className={`time-line__step bg-white relative md:w-2/5 xl:w-2/5 pl-10 mb-20 md:pb-10 lg:mb-10 z-10 ${index % 2 !== 0 ? 'ml-auto' : null}`}>
                  {/*<svg width="100%" height="100%">*/}
                  {/*   <line x1="380" y1="20" x2="230" y2="200" style={{stroke: '#000', strokeWidth: 5}} />*/}
                  {/*</svg>*/}
                  <svg className="svgDsk absolute hidden md:block md:top-11 md:left-full" width="100%"
                       height="100%"
                       version="1.1">
                     {/*<rect className="rect" width="100%" height="100%" style={{fill: "transparent", borderLeft: 0, borderBottom: 0, strokeWidth: 3, stroke: '#000'}} />*/}
                     <line className="animateLine lineHorizontal" x1="0%" y1="0%" x2="100%" y2="0%" style={{stroke: "#000", strokeWidth: 4}}/>
                     <line className="animateLine lineVertical" x1="100%" y1="0%" x2="100%" y2="100%" style={{stroke: "#000", strokeWidth: 4}}/>
                  </svg>
                  <svg className="svgMb absolute left-20 top-20 md:hidden" width="50%"
                       height="100%"
                       version="1.1">
                     <line className="animateLine lineVertical" x1="0%" y1="0%" x2="0%" y2="100%" style={{stroke: "#000", strokeWidth: 4}}/>
                  </svg>
                  {item.item_title.richText ? (
                     <div className="text-5xl font-serif bg-white pt-5 relative z-10">
                        <PrismicRichText field={item.item_title.richText} linkResolver={Utils.prototype.linkResolver} />
                     </div>
                  ) : null}
                  {item.item_description.richText ? (
                     <div className="text-xl pt-3 bg-white pb-5 relative">
                        <PrismicRichText field={item.item_description.richText} linkResolver={Utils.prototype.linkResolver} />
                     </div>
                  ) : null}
               </div>
            ))}
         </div>
      </div>
   </div>
)
export default TimeLine
