import React from "react";
import { PrismicRichText } from "@prismicio/react";
import Imgix from "react-imgix";
//CSS

const Appointment = ({ slice }) => {
  console.log(slice);
  return (
    <div className='appointment'>
      <div className='container mx-auto px-5 py-8'>
        <div className='w-full lg:w-10/12 mx-auto'>
          <div className='flex flex-wrap items-start'>
            <div className='w-60 h-auto rounded-full px-10 py-12 bg-gray_dark text-white font-serif text-4xl leading-snug overflow-hidden'>
              <PrismicRichText
                field={slice.primary.appointment_date.richText}
              />
            </div>
            <div className='w-8/12 ml-10 mt-10 lg:mt-36 text-xl font-light'>
              <PrismicRichText
                field={slice.primary.appointment_description.richText}
              />

              {slice.primary.appointment_image.url ? (
                <Imgix
                  className='lazyload transform transition-opacity duration-300 hover:opacity-70'
                  src={slice.primary.appointment_image.url}
                  width={slice.primary.appointment_image.dimensions.width}
                  height={slice.primary.appointment_image.dimensions.height}
                  htmlAttributes={{
                    alt: slice.primary.appointment_image.alt ?? "",
                  }}
                  attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                  }}
                />
              ) : null}
              <div>
                {slice.items.length > 1 ? (
                  <p className='text-2xl font-black mt-10 lg:mt-20'>
                    FORMATORI:
                  </p>
                ) : null}
                {slice.items.length === 1 ? (
                  <p className='text-2xl font-black mt-10 lg:mt-20'>
                    FORMATORE:
                  </p>
                ) : null}

                {slice.items.map((item, index) => (
                  <div
                    className='appointment__item'
                    key={`appointment-item=${index}`}
                  >
                    <div className='text-2xl lg:text-4xl font-black'>
                      <PrismicRichText
                        field={item.appointment_trainer.richText}
                      />
                    </div>
                    <div className='text-xl font-light'>
                      <PrismicRichText
                        field={item.appointment_trainer_description.richText}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
