import Seo from "./Seo";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import React from "react";
import Modal from "./modals/Modal";
import CloseMessage from "./modals/modals-content/CloseMessage";
import NewsletterModal from "./NewsletterModal";

const Layout = ({ children, document, prismicNavigation }) => {
  console.log("DOCUMENT", document);
  const currentLang = document.lang;
  {
    document.alternate_languages.map(
      (alternate, index) =>
        (document.alternate_languages[index].tags = document.tags)
    );
  }
  return (
    <div>
      <Helmet>
        <script>
          {`
                 var _iub = _iub || [];
                 _iub.csConfiguration = {
                   "countryDetection": true,
                   "enableFadp": true,
                   "floatingPreferencesButtonDisplay": "bottom-right",
                   "gdprAppliesGlobally": false,
                   "perPurposeConsent": true,
                   "siteId": "2411001",
                   "cookiePolicyId": ${
                     currentLang === "it-it"
                       ? 56313002
                       : currentLang === "de-de"
                       ? 90818359
                       : 16338958
                   },
                   "lang": "${
                     currentLang === "it-it"
                       ? "it"
                       : currentLang === "de-de"
                       ? "de"
                       : "en-GB"
                   }",
                   "banner":{ "acceptButtonCaptionColor":"#0F555A","acceptButtonColor":"#ffffff","acceptButtonDisplay":true,"backgroundColor":"#0f555a","closeButtonDisplay":false,"customizeButtonCaptionColor":"#0f555a","customizeButtonColor":"#ffffff","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonCaptionColor":"#0F555A","rejectButtonColor":"#FFFFFF","rejectButtonDisplay":true,"showPurposesToggles":true,"textColor":"white" },
                   "callback": {
                       onPreferenceExpressedOrNotNeeded: function(preference) {
                          dataLayer.push({
                             iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut()
                          });
                          if (!preference) {
                             dataLayer.push({
                                event: "iubenda_preference_not_needed"
                             });
                          } else {
                             if (preference.consent === true) {
                                dataLayer.push({
                                   event: "iubenda_consent_given"
                                });
                             } else if (preference.consent === false) {
                                dataLayer.push({
                                   event: "iubenda_consent_rejected"
                                });
                             } else if (preference.purposes) {
                                for (var purposeId in preference.purposes) {
                                   if (preference.purposes[purposeId]) {
                                      dataLayer.push({
                                         event: "iubenda_consent_given_" + purposeId
                                      });
                                   }
                                }
                             }
                          }
                       }
                    }
                 };
                
               `}
        </script>
        <script
          type='text/javascript'
          src='//cdn.iubenda.com/cs/iubenda_cs.js'
          charSet='UTF-8'
          async
        ></script>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
               new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
               j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
               'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
               })(window,document,'script','dataLayer','GTM-PB3XXTW5');`}
        </script>
      </Helmet>
      <Seo
        title={document.data.meta_title}
        nodeAttributes={document}
        description={document.data.meta_description}
      />
      {document.main_page_color ? (
        <Header
          navigation={prismicNavigation}
          nodeAttributes={document}
          bgColor={document.data.main_page_color}
        />
      ) : (
        <Header navigation={prismicNavigation} nodeAttributes={document} />
      )}
      <noscript>
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-PB3XXTW5'
          height='0'
          width='0'
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <main>{children}</main>
      <Footer navigation={prismicNavigation} />
      {document.lang === "it-it" ? ( //Render only for Italian
        <NewsletterModal />
      ) : null}
      {/* <Modal cookieName={"close_march_2024"}>
        <CloseMessage />
      </Modal> */}
    </div>
  );
};

export default Layout;
