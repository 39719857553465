import React from 'react'
import Imgix, {Background} from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
//CSS
import "../../styles/components/CardsGridLinks.scss"
import {Utils} from "../../Utilities";
import LinkResolver
   from "../LinkResolver";

const CardsGridLinks = ({slice}) => (
   <div className="cards_grid_links">
      <div className="container mx-auto px-5 py-16">
         <div className="w-full lg:w-10/12 mx-auto">
            <div className="w-full md:w-2/3 lg:w-1/2">
               <div className="text-4xl lg:text-5xl font-serif pb-5">
                  <PrismicRichText field={slice.primary.cards_grid_links_title.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
               <div
                  data-sal="fade"
                  data-sal-easing="ease"
                  className="text-xl font-light leading-7">
                  <PrismicRichText field={slice.primary.cards_grid_links_text.richText} linkResolver={Utils.prototype.linkResolver} />
               </div>
            </div>

            <div className="flex flex-wrap md:-mx-10">
               {slice.items.map((item, index) => (
                  <div
                     data-sal="slide-up"
                     data-sal-easing="ease"
                     className="w-full lg:w-1/3 px-5 lg:px-10 my-10 cards_grid_links__item" key={`cards-grid-links-item=${index}`}>
                     <Background src={item.cards_grid_links_background_image.url} className="aspect-w-16 aspect-h-14 flex flex-wrap relative">
                        <LinkResolver doc={item.link} classes="absolute h-full w-full z-10" label="&nbsp;" />
                        {/*<LinkResolver href={item.link.url} className="absolute h-full w-full z-10">&nbsp;</LinkResolver>*/}
                        <div data-sal='fade' data-sal-easing="ease" className="break-words text-white px-5 text-3xl md:text-3xl lg:text-2xl py-5">
                           <PrismicRichText field={item.title.richText} linkResolver={Utils.prototype.linkResolver} />
                        </div>
                        <Imgix
                           className="lazyload w-1/3 h-auto mt-auto -mb-5 -ml-3 lg:-ml-12 cards_grid_links__item__image duration-300 rounded-full"
                           src={item.round_image.url}
                           width={item.round_image.dimensions.width}
                           height={item.round_image.dimensions.height}
                           htmlAttributes={{
                              alt: item.round_image.alt ?? ''
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: "data-srcset",
                              sizes: "data-sizes",
                           }}/>
                     </Background>
                  </div>
               ))}
            </div>

         </div>
      </div>
   </div>
)

export default CardsGridLinks
