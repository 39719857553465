import React from "react";
import JSONDataIt from "./translations/it.json"
import JSONDataEn from "./translations/en.json"
const { defaultLanguage } = require('./prismic-configuration')

export class Utils {
   btnClass (className) {
      let btnClass = 'relative bg-white text-xl hover:bg-gray_dark text-gray_dark font-semibold hover:text-white py-2 px-4 border-4 border-gray_dark hover:border-transparent';
      switch (className) {
         case 'my-btn-gray_dark-outline':
            btnClass = 'relative bg-white text-xl hover:bg-gray_dark text-gray_dark font-semibold hover:text-white py-2 px-4 border-4 border-gray_dark hover:border-transparent'
            break
         case 'my-btn-white-outline':
            btnClass = 'relative bg-transparent text-xl hover:bg-white text-white font-semibold hover:text-gray_dark py-2 px-4 border-4 border-white hover:border-transparent'
         break

      }
      return btnClass;
   }

   /*
   * Prepare iframe to be used in plugin fslightbox as source
   */
   iframeObj(video) {
      let video_id = video.embed_url.split("/")
      video_id = video_id[video_id.length -1]
      let src = video.provider_url + '/embed/' + video_id;
      let iframe = <iframe type="text/html" width="1920" height="1080" src={src} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>

      return iframe

   }

   /* The Link Resolver
   * This function will be used to generate links to Prismic documents
   As your project grows, you should update this function according to your routes */

   linkResolver(doc) {
      function languageIso(lang) {
         return lang.substring(0, 2)
      }
      const properties = doc._meta || doc;
      if (properties.type === 'news') {
         return properties.lang === defaultLanguage
            ? `/news/${properties.uid}`
            : `/${languageIso(properties.lang)}/news/${properties.uid}`
      }
      if (properties.type === 'home_page') {
         if(properties.hasOwnProperty('tags')) {
            if(properties.uid === 'home') {
               return properties.lang === defaultLanguage
                  ? `/`
                  : `/${languageIso(properties.lang)}`
            } else if (properties.tags.includes('service')) {
               switch (properties.lang) {
                  case 'en-gb':
                     return `/${languageIso(properties.lang)}/house-renovation/${properties.uid}`
                  case 'de-de':
                     return `/${languageIso(properties.lang)}/haus-umbauen/${properties.uid}`
                  default:
                     return `/ristrutturare-casa/${properties.uid}`
               }
            }
         }
         return properties.lang === defaultLanguage
            ? `/${properties.uid}`
            : `/${languageIso(properties.lang)}/${properties.uid}`
      }
      if (properties.type === 'real_estate_object') {
         return properties.lang === defaultLanguage
            ? `/proposte-immobiliari/${properties.uid}`
            : `/${languageIso(properties.lang)}/proposte-immobiliari/${properties.uid}`
      }
      if (properties.type === 'design_styles') {
         switch (properties.lang) {
            case 'en-gb':
               return `/${languageIso(properties.lang)}/house-renovation/home-decor-ideas/${properties.uid}`
            case 'de-de':
               return `/${languageIso(properties.lang)}/haus-umbauen/wohnideen/${properties.uid}`
            default:
               return `/ristrutturare-casa/ispirazione-arredamento/${properties.uid}`
         }
      }
      if (properties.link_type === 'Web') {
         return properties.url;
      }
      if (properties.link_type === 'navigation') {
         return '/';
      }
      return `/${properties.uid}`;
   }

   languageIso(lang) {
      return lang.substring(0, 2)
   }

   pageScroll(target) {
      document.querySelector(target).scrollIntoView({
         behavior: 'smooth'
      });
   }

   dates(format) {
      let date_ob = new Date();

      let date = ("0" + date_ob.getDate()).slice(-2);

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // current year
      let year = date_ob.getFullYear();

      // current hours
      let hours = date_ob.getHours();

      // current minutes
      let minutes = date_ob.getMinutes();

      // current seconds
      let seconds = date_ob.getSeconds();

      if (format === 'date') {
         return year + "-" + month + "-" + date;
      }

      if (format === 'time') {
         return hours + ":" + minutes + ":" + seconds;
      }
   }
}
