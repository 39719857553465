import React from 'react'
import {PrismicRichText} from "@prismicio/react";
import {Utils} from "../../Utilities";
import Imgix from "react-imgix";
import LinkResolver
   from "../LinkResolver";
//Scss

const Divisions = ({slice}) => (
   <div className="divisions">
      <div className="bg-gray_dark">
         <div className="container mx-auto flex flex-wrap items-center py-10">
            <div className="w-8/12 text-4xl lg:text-5xl font-serif text-white px-5">
               <PrismicRichText field={slice.primary.title.richText}/>
            </div>
            <div className="w-4/12 text-white px-5">
               <Imgix
                  className="lazyload"
                  src={slice.primary.image.url}
                  alt={slice.primary.image.alt}
                  width={slice.primary.image.dimensions.width}
                  height={slice.primary.image.dimensions.height}
                  htmlAttributes={{
                     alt: slice.primary.image.alt ?? ''
                  }}
                  attributeConfig={{
                     src: 'data-src',
                     srcSet: "data-srcset",
                     sizes: "data-sizes",
                  }}/>
            </div>
         </div>
      </div>
      <div className="container mx-auto py-10">
         <div className="w-full xl:w-10/12 mx-auto">
            {slice.items.map((item, index) => (
               <div className="flex flex-wrap py-10" key={`division_${index}`}>
                  <div className="w-full px-5">
                     <div className="text-5xl font-serif italic leading-normal lg:leading-normal pb-5">
                        <PrismicRichText field={item.title.richText}/>
                     </div>
                  </div>
                  <div className="w-full md:w-7/12 px-5 order-2 md:order-1">
                     <div className="text-xl">
                        <PrismicRichText field={item.item_description.richText}/>
                     </div>
                  </div>
                  <div
                     data-sal="fade"
                     data-sal-easing="ease"
                     className="w-5/12 md:w-5/12 px-5 flex justify-center items-start mb-5 md:my-0 order-1 md:order-2">
                     <Imgix
                        className="lazyload"
                        src={item.item_logo.url}
                        width={item.item_logo.dimensions.width}
                        height={item.item_logo.dimensions.height}
                        htmlAttributes={{
                           alt: item.item_logo.alt ?? ''
                        }}
                        attributeConfig={{
                           src: 'data-src',
                           srcSet: "data-srcset",
                           sizes: "data-sizes",
                        }}/>
                  </div>
                  <div className="w-full order-3 lg:order-4 lg:w-7/12 px-5 mt-10 lg:mt-20 order-4 lg:order-3">
                     {item.video ? (
                        <div className="aspect-w-16 aspect-h-9" dangerouslySetInnerHTML={{ __html: item.video.html }} />
                     ) : (
                        <Imgix
                           className="lazyload"
                           src={item.item_image.url}
                           width={item.item_image.dimensions.width}
                           height={item.item_image.dimensions.height}
                           htmlAttributes={{
                              alt: item.item_image.alt ?? ''
                           }}
                           attributeConfig={{
                              src: 'data-src',
                              srcSet: "data-srcset",
                              sizes: "data-sizes",
                           }}/>
                     )}
                  </div>
                  <div className="w-full order-2 lg:order-4 lg:w-5/12 px-5 mt-10">
                     <div className="flex justify-center mt-10">
                        <div className="btn-with-gray-after-parent flex relative">
                           <LinkResolver doc={item.cta_link} classes={Utils.prototype.btnClass('my-btn-gray_dark-outline') + ' block text-center px-10'} label={item.cta_text} />
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   </div>
)
export default Divisions
