import React from 'react';
import Hero from './slices/Hero';
import Intro from './slices/Intro';
import IntroSecondVersion from './slices/IntroSecondVersion';
import IntroThirdVersion from './slices/IntroThirdVersion';
import Launch from "./slices/Launch";
import ImageCallToAction from "./slices/ImageCallToAction";
import CardsGridLinks from "./slices/CardsGridLinks";
import ImageSlider from "./slices/ImageSlider";
import ImagesGrid from "./slices/ImagesGrid";
import CallToActionRich from "./slices/CallToActionRich";
import NewsletterSubscription from "./slices/NewsletterSubscription";
import News from "./slices/News";
import NewsAcademy from "./slices/NewsAcademy";
import ListOfServices from "./slices/ListOfServices";
import PartnersSection from "./slices/PartnersSection"
import MaterialList from "./slices/MaterialList"
import Masonry from "./slices/Masonry";
import DesignStyledList from "./slices/DesignStyledList";
import List from "./slices/List";
import Icons from "./slices/Icons";
import TextBanner from "./slices/TextBanner";
import Map from "./slices/Map";
import TimeLine from "./slices/TimeLine";
import Video from "./slices/Video";
import Divisions from "./slices/Divisions";
import TeamSection from "./slices/TeamSection";
import RealEstateObjectsList from "./slices/RealEstateObjectsList";
import TwoColumnsText from "./slices/TwoColumnsText";
import ContactFormSection from "./slices/ContactFormSection";
import AllNews from "./slices/AllNews";
import AcademyRender from "./slices/AcademyRender";
import Appointment from "./slices/Appointment";
import ContestParticipation from "./slices/ContestParticipation";

const SliceZone = ({ sliceZone, nodeAttributes }) => {
   const sliceComponents = {
      hero: Hero,
      intro: Intro,
      intro_v2: IntroSecondVersion,
      intro_v3: IntroThirdVersion,
      launch: Launch,
      image_call_to_action: ImageCallToAction,
      cards_grid_links: CardsGridLinks,
      image_slider: ImageSlider,
      images_grid: ImagesGrid,
      call_to_action_rich: CallToActionRich,
      newsletter_subscription: NewsletterSubscription,
      news: News,
      'news_-_academy': NewsAcademy,
      list_of_services: ListOfServices,
      customer_logos: PartnersSection,
      material_list: MaterialList,
      masonry: Masonry,
      design_styled_list: DesignStyledList,
      list: List,
      icons: Icons,
      text_banner: TextBanner,
      map: Map,
      time_line: TimeLine,
      video: Video,
      divisions: Divisions,
      team_section: TeamSection,
      real_estate_objects_list: RealEstateObjectsList,
      two_columns_text: TwoColumnsText,
      contact_form: ContactFormSection,
      academyrender: AcademyRender,
      apointment: Appointment,
      all_news: AllNews,
      contestparticipation: ContestParticipation,
   }
   // return sliceZone.map((slice, index) => (
   //    <p key={index}>{slice.slice_type}</p>
   // ))
   const sliceZoneContent = sliceZone.map((slice, index) => {
      const SliceComponent = sliceComponents[slice.slice_type]
      //console.log("==============")
      //console.log(slice.slice_type)
      // console.table(slice)
      if (SliceComponent) {
         //console.log(slice)
         return <SliceComponent slice={slice} nodeAttributes={nodeAttributes} key={`slice-${index}`} />
      }
      return null
   })

   return <main>{sliceZoneContent}</main>
}

export default SliceZone
