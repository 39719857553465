import React from 'react'
import Imgix from "react-imgix";
import {PrismicRichText} from "@prismicio/react";
import {StaticQuery, graphql, Link} from 'gatsby';
import Carousel from 'react-multi-carousel'
//css
import "react-multi-carousel/lib/styles.css";
import LinkResolver
   from "../LinkResolver";
import {Utils} from "../../Utilities";
const responsive = {
   superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
   },
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
   }
};

const News = ({slice, nodeAttributes}) => (
   <StaticQuery
      query={graphql`
      {
        allPrismicNews(limit: 5, sort: {fields: data___date, order: DESC}) {
          edges {
            node {
              id
              uid
              type
              lang
              url
              data {
                title {
                  richText
                  text
                }
                image {
                  alt
                  dimensions {
                    height
                    width
                  }
                  url
                }
                date
                meta_title
                meta_description
                body {
                  richText
                }
              }
            }
          }
        }
      }
    `}
      render={data => (

            <div className="news bg-gray_light py-20">

               <div className="container mx-auto px-5">
                  <div className="w-full lg:w-10/12 mx-auto">
                     <div className="text-4xl lg:text-5xl font-serif pb-5 inline-block">
                        <PrismicRichText field={slice.primary.news_title.richText} linkResolver={Utils.prototype.linkResolver} />
                     </div>
                     <Carousel  className="-mx-5 mt-5" responsive={responsive}>
                        {data.allPrismicNews.edges.map((element, index) => {
                           return element.node.lang ===  nodeAttributes.lang ? (
                              <div className="px-5" key={`item=${index}`}>
                                 <Link to={Utils.prototype.linkResolver(element.node)}>
                                    <Imgix
                                       className="lazyload"
                                       src={element.node.data.image.url}
                                       alt={element.node.data.image.alt}
                                       width={element.node.data.image.dimensions.width}
                                       height={element.node.data.image.dimensions.height}
                                       htmlAttributes={{
                                          alt: element.node.data.image.alt ?? ''
                                       }}
                                       attributeConfig={{
                                          src: 'data-src',
                                          srcSet: "data-srcset",
                                          sizes: "data-sizes",
                                       }}/>
                                 </Link>
                                 <p className="font-serif text-sm mt-5 italic">{element.node.data.date}</p>
                                 <div className="text-xl lg:text-2xl font-bold">
                                    <LinkResolver doc={element.node} label={element.node.data.title.text} />
                                 </div>
                              </div>
                           ): null})}

                     </Carousel>


                  </div>
               </div>
            </div>
      )}
   />
)

export default News
