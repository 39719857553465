/*
* The Link Resolver is a simple function that takes in a Prismic document object or Link field
* and returns the corresponding URL for that page in your site.
*/
import React from "react";
import {Link} from "gatsby";
import {Utils} from "../Utilities";

const LinkResolver = ({ classes, doc = null, label }) => {
   if (doc) {
      if (doc.link_type === 'Document') {
         return (
            <Link className={classes} to={Utils.prototype.linkResolver(doc)}>{label}</Link>
         )
      } else if (doc.link_type === 'Web') {
         return (
            <a className={classes} href={Utils.prototype.linkResolver(doc)} target="_blank">{label}</a>
         )
      } else {
         return (
            <Link className={classes} to={Utils.prototype.linkResolver(doc)}>{label}</Link>
         )
      }
   }

   return '/'

}

export default LinkResolver
